.root {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
}

.banner {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffb169;
  width: 100%;
  padding: 0 61px;
  color: #fff;
  @media (max-width:576px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding: 16px 24px;
  }
}

.socialMediaLinks {
  display: flex;
  gap: 10px;
  & svg {
    fill: #fff;
    &:hover {
      fill: #fff;
    }
  }
}
