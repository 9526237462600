@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  @media (--viewportMedium) {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 20px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 0;

  & button {
    border-radius: 100px;
    margin-bottom: 20px;
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.multiselectInput {
  margin-bottom: 36px;
}

.exactLocationText {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4661ED;
  margin-top: 15px;
  margin-bottom: 32px;
}

.workcheckBox {
  margin-top: 32px;
  margin-bottom: 21px;
}

.inputBox {
  &>div {
    &>div {
      &>div {
        border-bottom: 0;
        position: absolute;

        & svg {
          display: none;
        }
      }
    }
  }
}

.descriptionRoot {
  margin-top: 30px;
  margin-bottom: 40px;

  &>div {
    background: #FFFFFF;
    border: 1px solid #939393;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px 10px 20px 20px;

    &:nth-child(3) {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    & textarea {
      height: 104px !important;
      overflow-y: auto !important;
      border: none;
      padding: 0;
    }
  }
}