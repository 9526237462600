@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #939393;
  border-radius: 4px;
  padding: 12px 16px;
  /* border-bottom: 2px solid var(--attentionColor); */

  &:hover,
  &:focus {
    /* border-bottom-color: var(--matterColorDark); */
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    /* height: 40px; */
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  /* border-bottom-color: var(--successColor); */
}

.locationAddress,
.building {
  flex-shrink: 0;
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    /* margin-bottom: 32px; */
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 25px;
  position: relative;

  @media (--viewportLarge) {
    display: inline-block;
    width: 100%;
    margin-top: 48px;
  }

}

.rowBox {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  flex-direction: column;

  @media(--viewportSmall) {
    flex-direction: row;
    margin-top: 28px;
  }

  & .rowInput {
    width: 100%;

    &:nth-child(2) {
      margin-top: 26px;

      @media(--viewportSmall) {
        margin-top: 0;
      }
    }

    @media(--viewportSmall) {
      width: calc(50% - 9px);
    }

    &>div {
      &>div {
        &>div {
          height: 45px;
          border-color: #939393;
          box-shadow: none;
        }
      }
    }
  }
}

.eventSize {
  margin-top: 28px;
}

.selectRadioButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media(max-width: 560px) {
    flex-direction: column;
  }

  & .radioBox {
    /* margin-right: 39px; */

    & label {
      @media(max-width: 560px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      & span {
        @media(max-width: 560px) {
          margin-left: 0;
        }
      }
    }
  }
}

.signupSubHeading {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  padding-top: 30px;
  margin-top: 47px;
  margin-bottom: 26px;
  border-top: 1px solid #E0E0E0;
}

.timeBox {
  margin: 36px 0;
}

.warningMessage {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #4661ED;
  margin-top: 17px;
  margin-bottom: 31px;
  text-align: center;

  @media(--viewportMedium) {
    margin-top: 15px;
    text-align: right;
    /* margin-bottom: 0; */
  }
}

.arrowIcon {
  position: absolute;
  right: 43px;
  top: 15px;
  & svg{
    @media(max-width:768px){
      width: 20px;
      height: 20px;
    }
  }
}

.tableDate {
  text-align: left;
  width: 30%;
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.tableHeading {
  text-align: left;
  width: 23.3%;
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.tableData {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.eventTable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.removeExceptionButton {
  border: 1px solid #212121;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212121;
  cursor: pointer;

  & svg {
    & g {
      fill: #fff;
      stroke: #fff;
    }
  }
  @media(max-width: px){

  }
}

.addAnotherDate{
  & a{
    background-color: var(--marketplaceColor);
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:30px;
    width: max-content;
    min-width: 130px;
    padding: 0 20px;
  }
}