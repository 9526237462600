@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(#location-hero),
:global(#vendors),
:global(#learn-more),
:global(#recently-added),
:global(#section-title),
:global(#event-sizes),
:global(#section-title-2) {
  max-width: 1530px;
  margin: 0 auto;
  background-color: #fff;
}
