.swiperSlide {
    position: relative;

    & :global(.slick-slider) {
        max-width: 1581px;
    }

    & :global(.slick-list) {
        padding-top: 70px;
        padding-bottom: 10px;
    }
}

.trendCard {
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-decoration: none;

    @media(max-width: 1440px) {
        min-width: 30%;
        width: 100%;
    }

    @media(max-width: 960px) {
        min-width: 38%;
        width: 100%;
    }

    @media(max-width: 768px) {
        min-width: 40%;
        width: 100%;
    }

    @media(max-width: 680px) {
        min-width: 55%;
        width: 100%;
    }

    @media(max-width: 520px) {
        min-width: 65%;
        width: 100%;
    }

    @media(max-width: 460px) {
        min-width: 75%;
        width: 100%;
    }

    @media(max-width: 414px) {
        min-width: 85%;
        width: 100%;
    }

    &:hover {
        text-decoration: none;
    }
}

.sliderCard {
    padding-right: 95px;

    @media(max-width: 1550px) {
        padding-right: 30px;
    }
}

.trendingBody {
    padding: 14px;
    text-align: center;

    & h5 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #595858;
        margin: 0;
    }

    & .categoriesText {
        margin-bottom: 16px;

        & span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-transform: capitalize;
            color: #595858;

            & svg {
                margin: 0 5px;

                & path {
                    fill: #212121;
                }
            }
        }
    }

    & .addList {
        margin-top: 8px;
        position: relative;
        display: block;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 38px;
        letter-spacing: 0.15em;
        text-decoration: none;
        color: #000000;
        background: none;
        min-height: unset;
        text-decoration: none;
        box-shadow: none;

        &:hover {
            text-decoration: none;
            box-shadow: none;
        }
    }
}

.titleTop {
    display: flex;
    align-items: center;
    margin-bottom: 92px;
    justify-content: space-between;

    @media(max-width: 1440px) {
        margin-bottom: 42px;
    }

    & h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.18px;
        color: #000000;
        font-family: 'Avenir LT Std', sans-serif;
        margin: 0;

        @media(max-width: 768px) {
            font-size: 18px;
        }
    }

    & .seeAll {
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #4661ED;
        font-family: 'Avenir LT Std', sans-serif;

        @media(max-width: 768px) {
            font-size: 14px;
        }
    }
}

.trendTop {
    position: relative;

    &>div {
        border-radius: 8px 8px 0 0;
    }


    & .cardImage {
        border-radius: 8px 8px 0px 0px;
        width: 100%;
        height: 167px;
    }

    & .brandLogo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        & img {
            width: 123px;
            height: 123px;
        }
    }
}

.viewDetailButton {
    margin-top: 16px;
    position: relative;
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: 0.15em;
    background-color: #212121;
    color: var(--matterColorLight);
    min-height: 56px;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}