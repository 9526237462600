@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    max-width: 1080px;
    margin: auto;
  }
}
.actionBtns {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.nav {
  /* Layout */
  /* display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll; */
  background-color: #0F0F0E;
  padding: 22px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportLarge) {
    padding: 60px 54px;
    width: 354px;
    /* padding: 128px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none; */
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 128px 0 82px calc((100% - 1056px) / 2); */
  }
}

.tab {
  margin-left: 0;
  white-space: nowrap;
  margin-bottom: 22px;
  width: 180px;

  @media(max-width: 1024px) {
    display: none;
  }

  & a {
    letter-spacing: 0.12em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
  }

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  /* flex-grow: 1; */
  background-color: #fff;
  padding: 26px;

  @media(max-width: 768px) {
    overflow-y: auto;
    min-height: calc(100vh - 190px);
    box-sizing: border-box;

    /* &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    } */
  }

  @media(--viewportMedium) {
    padding: 52px;
  }

  @media (--viewportLarge) {
    padding: 66px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}