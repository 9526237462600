@import '../../../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.mainWrapper {
  background-color: #fff;
  /* padding: 61px 85px; */
}

.mainContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 1581px;
  width: 100%;
  padding: 60px 0;

  @media (max-width: 1700px) {
    padding: 60px;
  }

  @media (max-width: 1160px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
}

.noSpace {
  margin-left: auto;
  margin-right: auto;
  max-width: 1581px;
  width: 100%;
  padding: 0 0 60px 0;

  @media (max-width: 1700px) {
    padding: 60px;
  }

  @media (max-width: 1160px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
}

.swiperSlide {
  @media (max-width: 1024px) {
    padding: 0 100px;
  }

  @media (max-width: 620px) {
    padding: 0 50px;
  }

  & :global(.slick-slide) > div > div {
    /* padding: 0 25px; */

    @media (max-width: 620px) {
      padding: 0 10px;
    }

    /* @media (max-width: 920px) {
            padding: 0 10px;
        }

        @media (max-width: 600px) {
            padding: 0 50px;
        }

        @media (max-width: 460px) {
            padding: 0 10px;
        } */
  }

  & :global(.slick-next):before,
  & :global(.slick-prev):before {
    content: unset;
  }

  & :global(.slick-arrow) {
    /* right: 40%; */
    top: unset;
    bottom: 44%;
  }

  & :global(.slick-next) {
    right: -25px;

    @media (max-width: 1024px) {
      right: 26px;
    }
  }

  & :global(.slick-prev) {
    left: -36px;
    z-index: 5;

    @media (max-width: 1024px) {
      left: 26px;
    }
  }
}

.tabsSelected {
  color: #4661ed;
  font-weight: 700;
}

.tabsText {
  color: #595858;
}

.homeTabs {
  & a {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;
  }

  & .active {
    background-color: red;
  }

  & > div {
    margin-left: 0;
    margin-right: 35px;

    & a {
      width: auto;
      margin-bottom: 0;

      & span {
        padding: 0;
      }
    }
  }
}

.activeLink > span {
  font-weight: 700 !important;
  color: #1fd1bc !important;
}

.categoriesTabs {
  margin-bottom: 31px;

  & .homeTabs {
    flex-wrap: wrap;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.secondSection {
  margin-top: 69px;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  /* composes: sectionContent;
  margin-top: 3vh; */
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.lineDivider {
  margin: 43px 0;
}

.noLoginSection {
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 25px;
}

.catSectionHeading {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #21201f;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.seeMoreLink {
  margin-bottom: 70px;
  text-align: right;

  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }

  & a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.15em;
    color: #1419a4;
  }
}

.venSectionHeading {
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #21201f;
  /* margin-top: 85px; */
  margin-bottom: 94px;

  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }
}

.seeMore {
  margin-bottom: 50px;
  text-align: right;

  & a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.15em;
    color: #1419a4;
  }
}

.learnVendor {
  padding-top: 70px;
  padding-bottom: 90px;

  & .vendorSectionHeading {
    font-family: 'Martel Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #21201f;
    margin-bottom: 89px;

    @media (max-width: 1024px) {
      margin-bottom: 30px;
    }

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  & .vendorGrid {
    display: flex;
    gap: 62px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }

    & .vendorDescription {
      width: 50%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      & .longDescription {
        font-family: 'Martel';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.18px;
        text-transform: capitalize;
        color: #000000;

        @media (max-width: 1024px) {
          margin-bottom: 15px;
        }
      }

      & .bottomButton {
        text-align: center;

        & a {
          background: #ffb169;
          border: 1px solid #ffffff;
          border-radius: 100px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 38px;
          text-align: center;
          letter-spacing: 0.15em;
          color: #000000;
          padding: 7px 20px;
          height: 51px;
          width: 296px;
          display: block;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    & .vendorImage {
      @media (max-width: 1200px) {
        width: 100%;
      }

      & img {
        @media (max-width: 1200px) {
          height: 300px;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

.bottomButtonSubmit {
  text-align: center;
  margin-top: 34px;

  & button {
    background: #ffb169;
    border: 1px solid #ffffff;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #000000;
    padding: 7px 20px;
    height: 51px;
    width: 296px;
  }

  & a {
    background: #ffb169;
    border: 1px solid #ffffff;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #000000;
    padding: 7px 20px;
    height: 51px;
    width: 296px;
    display: block;
    margin: auto;
  }
}

.titleTop {
  display: flex;
  margin-bottom: 43px;
  align-items: center;
  justify-content: space-between;

  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #000000;
    font-family: 'Avenir LT Std', sans-serif;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  & .seeAll {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ed;
    font-family: 'Avenir LT Std', sans-serif;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.vendorEventSize {
  padding-bottom: 85px;
}

.coverBox {
  & img {
    width: 100%;
    height: 304px;
    object-fit: cover;
  }
}

.shortDescription {
  margin-top: 90px;
  font-family: 'Martel';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  color: #000000;

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
}

.categoriesName {
  text-align: center;
  font-family: 'Martel Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #21201f;
  margin-bottom: 52px;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.categoriesLink {
  &:hover {
    text-decoration: none;
  }
}

.imageBack {
  /* position: relative; */
  /* height: 203px; */
  /* display: flex;
    align-items: center;
    justify-content: center; */

  & .roundIcon {
    height: 203px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 203px;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
    margin: auto;

    @media (max-width: 768px) {
      margin: auto;
      height: 150px;
      width: 150px;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(34, 29, 29, 0.45);
      top: 0;
      border-radius: 50%;
    }

    /* position: absolute; */

    /* & img {
            border-radius: 50%;
            width: 203px;
            height: 203px;
            object-fit: cover;

            @media(max-width: 768px) {
                width: 160px;
                height: 160px;
            }
        }

        & .shadeBox {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(34, 29, 29, 0.45);
            top: 0;
            border-radius: 50%;
        } */
  }

  & .textName {
    z-index: 9;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.12em;
    color: #ffffff;
    padding: 0 15px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.caterogiesBox {
  margin-bottom: 70px;
}

.discoverText {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #21201f;
  padding: 30px 0;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.cateroriesSlider {
  & ul {
    gap: 38px;
    flex-direction: row;
    flex-wrap: unset;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 16px;
    padding-top: 70px;
    padding-left: 5px;
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      height: 5px;
      background-color: #21212173;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #212121;
    }

    &::-webkit-scrollbar:vertical {
      display: none;
    }

    & li {
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      flex: 0 0 auto;
      width: 100%;

      @media (max-width: 1300px) {
        width: 300px;
      }
    }
  }
}
.smallImage {
  width: 400px;
}

.largeImage {
  width: 673px;
}

.citySlider {
  & :global(.slick-slide) {
    padding: 20px 0;
    & > div {
        max-width: 302px;
        padding-left: 10px;
      & > div {
      }
    }
  }
  & :global(.slick-list){
    @media (max-width:1500px) {
      max-width: calc(100vw - 40px);
    }
  }
}
