@import '../../styles/customMediaQueries.css';

.welcomeBackground {
    background-image: url('../../assets/auth-images/authbackground.png');
    background-size: 100%;
    padding: 10em 0;

    @media(max-width: 1200px) {
        background-size: 100% 100%;
    }

    @media(max-width: 768px) {
        padding: 10em 25px;
        background-size: cover;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        height: 60em;
        width: 100%;
        z-index: 0;
    }
}

.finishBannerImg {
    background-image: url('../../assets/auth-images/authbackground.png');
}

.content {
    /* width: calc(100% - 48px); */
    /* margin: 12px 24px 24px 24px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 40px 25px;

    @media (--viewportMedium) {
        padding: 36px 80px;
        width: 592px;
        margin: auto;
        /* height: 477px; */
        /* margin: 55px auto 56px auto; */
    }
}

.welcomeBox {
    & .tosterHeadingText {
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #000000;
        margin: 0;
    }

    & .headingText {
        margin: 0;
        font-weight: 300;
        font-size: 26px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.12em;
        color: #000000;
        margin-bottom: 55px;

        & span {
            display: block;
        }
    }
}

.BottomButtonBox {
    display: flex;
    flex-direction: column;
}

.loginCenterLogo {
    text-align: center;
    margin-top: -100px;
    & .chatImage{
        width: 138px;
        height: 138px;
        border-radius: 50%;
        margin: 0 auto;
        & img{
            width: 138px;
            height: 138px;  
            border-radius: 50%;
          }
    }
}

.welcomeBox {
    & .tosterHeadingText {
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #000000;
        margin: 0;
    }

    & .headingText {}
}

.borderButton {
    margin-top: 29px;
    background: #FFFFFF;
    border: 1px solid #212121;
    border-radius: 100px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #21201F;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 460px) {
        font-size: 16px;
    }

    &:hover {
        text-decoration: none;
    }
}

.requestButton {
    background: #21201F;
    border: 1px solid #212121;
    border-radius: 100px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.12em;
    color: #FFFFFF;

    @media(max-width: 460px) {
        font-size: 16px;
    }

    &:hover {
        text-decoration: none;
    }
}

.tosterHeadingText {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #000000;
    margin: 0;
}

.headingText {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-weight: 300;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #000000;
    margin-bottom: 40px;
}

.thanksText {
    text-align: center;
    margin-top: 29px;

    & a {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #4661ED;
    }
}