@import '../../styles/customMediaQueries.css';

.root {
}

.eventCardBox {
  /* background: #ffffff; */
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .rightBoxIcon {
    position: absolute;
    width: 100%;
    top: 0;
    justify-content: flex-end;
    display: flex;
    height: 49px;
    align-items: center;

    & span {
      padding: 0 10px;
      background: rgba(255, 255, 255, 254.25);
      border-radius: 0px 0px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      height: 100%;

      &:nth-child(2) {
        border-radius: 0px 4px;
      }
    }
  }

  & .topBarBlack {
    position: absolute;
    top: 0;
    width: 100%;
    background: #000000a1;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    height: 49px;
    align-items: center;
    padding: 0 10px;

    & h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 38px;
      margin: 0;
      color: #ffffff;
      text-transform: capitalize;
      letter-spacing: normal;

      @media (max-width: 1300px) {
        line-height: 18px;
      }
    }

    & .rightBox {
      display: flex;

      & span {
        margin-left: 24px;
        cursor: pointer;
      }
    }
  }

  & .eventTop {
    padding: 14px;
    & img {
      height: 156px;
      width: 376px;
      border-radius: 4px;
      min-width: 100%;

      @media (max-width: 1300px) {
        width: 100%;
      }
    }
  }

  & .eventBody {
    padding: 16px 20px 24px 20px;

    & h3 {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.15em;
      color: #1b1a1a;
      transform: matrix(1, -0.01, 0, 1, 0, 0);
      margin: 0;
    }

    & ul {
      margin-top: 15px;
      margin-bottom: 0;

      & li {
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #595858;
      }
    }
  }

  & .bottomButton {
    padding: 0 20px 20px;
    & a {
      border-radius: 4px;
      height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #212121;

      &:hover {
        text-decoration: none;
      }
    }

    & button {
      border-radius: 4px;
      height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #212121;

      &:hover {
        text-decoration: none;
      }
    }

    & .pendingButton {
      background-color: #4661EE;
      color: #fff;
      &.requestedBtn{
        background-color: #FFB169;
        color: #fff;
      }
      &.pendingBtn{
        background-color: #000;
        color: #fff;
      }
      &.acceptedBtn{
        background-color: #F0EA6E;
        color: #fff;
      }
    }

    & .expiredButton {
      background-color: #595858;
      color: #fff;
    }
 
  }
}
.eventCardDisabled {
  cursor: not-allowed;
}

.location,
.status {
  min-width: 250px;
}
.lazyLoad {
  text-align: center;

  & .lazySkeleton {
    & svg {
      width: 100%;
    }
  }
}

.mainWrapperListBox {
  margin-top: 40px;

  & .tableViewBox {
    max-width: calc(100vw - 40px);
    overflow-x: auto;
    /* @media(max-width: 1024px) {
      display: none;
    } */

    & table {
      width: 100%;

      & th {
        background: #f8f8f8;
        box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        padding: 27px 17px;
        text-align: left;

        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 17%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 18%;
        }

        & .headingName {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #333333;
        }
      }

      & tbody {
        & tr {
          background: #ffffff;
          box-shadow: inset 0px -1px 0px #e5e5e5;

          &:hover {
            background: #fffcf8;
          }
        }

        & td {
          padding: 9px 17px;

          & .tableData {
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            letter-spacing: 0.05em;
            color: #212121;
          }

          & .tableDate {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.05em;
            color: #595858;
          }

          & .tableButton {
            & a {
              min-height: 24px;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
              text-align: center;
              border-radius: 4px;
              font-family: 'Montserrat';
              /* color: #151414; */
              padding: 4px 17px;
              text-decoration: none;
            }

            & button {
              min-height: 24px;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
              text-align: center;
              border-radius: 4px;
              font-family: 'Montserrat';
              color: #151414;
              padding: 4px 17px;
              text-decoration: none;
            }

            & .pendingButton {
              background-color: #f0eb6f;
              color: #151414;
              width: 100%;
              display: block;
            }

            & .expiredButton {
              background-color: #595858;
              color: #fff;
            }
          }
        }
      }
    }
  }

  & .cardViewBox {
    /* @media(max-width: 1024px) {
      display: block;
    } */

    & .eventCardsList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 62px;
      row-gap: 43px;
      background-attachment: red;

      @media (max-width: 1440px) {
        gap: 30px;
      }

      @media (max-width: 1024px) {
        gap: 20px;
      }

      @media (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 620px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .blankCard {
    }
  }
}

.viewDetailsBtn {
  background-color: #000;
  color: #fff;
  width: 100%;
  display: block;
}

.leaveFeedbackBtn {
  background-color: #f0eb6f;
  color: #000;
  font-weight: 500 !important;
  width: 100%;
  display: block;
}

.expiredBtn {
  background-color: #9e9e9e;
  color: #000;
  font-weight: 500 !important;
  width: 100%;
  display: block;
}

.proposalPendingBtn {
  color: #fff;
  background-color: #595758;
  font-weight: 500 !important;
  width: 100%;
  display: block;
}

.waitingBtn {
  background-color: #4761ee;
  color: #fff;
  width: 100%;
  display: block;
  & svg {
    margin-right: 8px;
  }
}

.bidAcceptedBtn {
  background-color: #ffb169;
  color: #000;
  font-weight: 500 !important;
  width: 100%;
    display: block;
  & svg {
    margin-right: 8px;
  }
}

.menu { 
  display: none;
}

.cardIsOpen {
  display: block;
}

.menubarWrapper {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
}

.menubarGradient {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Gradient */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAqCAYAAACUV/o4AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAGhJREFUKBVjZGBgKANibiAGga8sQAKEf4N4QMAC4vwH4r8gHgiABOAcmMAfEAMGsKrA0DIqAAsvHGFKkwACRRQjzGJQRP2DcUA0LOZAEQ5S9R8mAJIEA6wCyIYSqYVSz4FcxwR1E5gCAD7HLabS/HFUAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  opacity: 0.5;
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 9px 0 8px;
}

.menuContent {
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);
  border-radius: 4px;
  padding: 0 10px;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: var(--boxShadowPopup);
}

.menuItem {
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  color: var(--matterColorLight);
  white-space: nowrap;
  text-align: left;
  padding: 8px 14px;
  border-radius: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    background-color: var(--failColor);
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.menuItemDisabled {
  color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorNegative);
  }
}

.manageLinks {
  composes: h5 from global;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.manageLink {
  text-decoration: none;
  color: #000;
  white-space: pre;
  display: flex;
  flex-direction: column;
  flex: 1;
  &:hover {
    text-decoration: underline;
    /* color: var(--matterColorDark); */
  }
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}
