@import '../../styles/customMediaQueries.css';

.shareModal {
  z-index: 100000 !important;

  @media (--viewportMedium) {
    & > div {
      & > div {
        flex-basis: 650px !important;

        & > button {
          padding: 0 !important;
          margin: 48px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    & > div {
      & > div {
        width: 100% !important;
        max-width: 100% !important;
        min-height: 200px !important;
        bottom: 0;
        position: absolute !important;
        margin: 0 !important;
        border-radius: 5px 5px 0 0 !important;
        justify-content: flex-end;
        padding: 0 !important;

        background: #000000 !important;

        & > button {
          display: none;
        }

        & .modalContent {
          padding: 20px 12px;

          & > div {
            margin-bottom: 16px !important;

            &:last-child {
              padding-top: 0;

              & span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.dblock {
  display: block;
}

.enquirySuccessModal {
  @media (max-width: 767px) {
    background: #fff;
    padding: 20px 0px 20px 22px;
    border-radius: 12px 12px 0px 0px;
  }

  width: 100%;

  & .shareWith {
    width: 100%;

    & h1,
    & h2 {
      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      & .copyBoard {
        display: none !important;
      }
    }

    & p {
      margin-bottom: 8px;
    }

    & .socialMedialinks {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 18px;
      margin-top: 24px;
      margin-bottom: 0;

      @media (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 10px;
        padding-bottom: 40px;
        padding-top: 30px;
        padding-right: 22px;
        max-width: calc(100vw - 22px);
        grid-gap: 12px;

        &::-webkit-scrollbar {
          width: 0px;
          height: 0;
        }
      }

      & li {
        font-style: normal;
        letter-spacing: -0.04em;
        color: #484848;
        cursor: pointer;
        transition: all ease 0.5s;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (--viewportMedium) {
          max-width: 276px;
          width: calc(50% - 12px);
          border: 1px solid #d1d1d1;
          flex-direction: row;
          align-items: center;
          padding: 15px 20px;
          border-radius: 5px;
        }

        & button {
          padding: 15px 20px;
        }

        & span {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #474747;
          letter-spacing: 0.5px;
          display: block;
          margin-top: 6px;
          white-space: pre;

          @media (--viewportMedium) {
            font-size: 16px;
            margin-top: 0px;
            line-height: 26px;
          }
        }

        &:hover {
          background: #eef7ff;
        }

        & svg {
          height: 30px;
          width: 30px;

          @media (--viewportMedium) {
            margin-right: 16px;
            height: 28px;
            width: 28px;
          }
        }

        & img {
          height: 30px;
          width: 30px;
          border-radius: 10px;

          @media (--viewportMedium) {
            margin-right: 16px;
            height: 28px;
            width: 28px;
          }
        }

        & button,
        & a {
          color: #484848;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;

          @media (--viewportMedium) {
            flex-direction: row;
            align-items: center;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    & .responsiveCopyLink {
      padding: 0 20px 0 0;

      @media (min-width: 768px) {
        display: none;
      }

      & .copyTitle {
        font-size: 16px;
        line-height: 26px;
        margin: 0 0 6px;
      }

      & .linkCopyWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f5f5f5;
        padding: 9px 16px;
        border-radius: 5px;

        & .linkCopy {
          font-size: 16px;
          line-height: 26px;
          margin: 0 6px 0 0;
          max-width: 229px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      & .shareBY {
        font-size: 16px;
        line-height: 26px;
        margin: 20px 0 0 0;
      }
    }
  }

  & h1,
  & h2 {
    color: #292929;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin: 10px 0 0;
  }

  & form {
    margin-bottom: 28px;
  }

  & .complaintReceived {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
      width: auto;
      display: inline-block;
      border: none;
      background: #1f1f1f;
      border-radius: 4px;
      height: 48px;
      padding: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #ffffff;
      cursor: pointer;

      &.closeModal {
        background-color: #fff;
        color: #484848;
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          color: #000000;
        }
      }

      & .inProgress {
        background: #1f1f1f;
        color: #fff;
      }
    }

    &.fullWidth {
      & button {
        width: 100%;
        display: inline-block;
        border: none;
        background: #1f1f1f;
        border-radius: 4px;
        height: 48px;
        padding: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.04em;
        color: #ffffff;
        cursor: pointer;

        & .inProgress {
          background: #1f1f1f;
          color: #fff;
        }
      }
    }
  }

  & .continueBtn {
    justify-content: flex-end;

    & button {
      min-width: 150px;
      display: inline-block;
      border: none;
      background: #1f1f1f;
      border-radius: 4px;
      height: 48px;
      padding: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #ffffff;
      cursor: pointer;

      & .inProgress {
        background: #1f1f1f;
        color: #fff;
      }
    }
  }
}

.hotelInfo {
  display: flex;
  margin-top: 48px;

  & .hotelImage {
    margin-right: 12px;
    max-width: 180px;
    height: 110px;
    overflow: hidden;
    min-width: 180px;
    position: relative;

    & > div {
      width: 100%;
    }

    & img {
      border-radius: 5px;
      /* width: 100%; */
    }
  }

  & .hotelDetails {
    & p {
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: #7b7b7b;
      letter-spacing: 0.25px;
      margin: 4px 0 0;
    }

    & .checkInCheckOut {
      & p {
        margin: 0px 0 0;
        line-height: 22px;

        &:first-child {
          position: relative;
          margin-top: 16px;

          &:after {
            content: '';
            background: #c4c4c4;
            width: 20px;
            height: 2px;
            position: absolute;
            left: 0;
            top: -8px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.textCopiedPopup {
  position: fixed;
  bottom: 40px;
  left: 40px;
  display: flex;
  align-items: center;
  background: #292929;
  box-shadow: 2px 2px 12px rgba(7, 21, 34, 0.17);
  border-radius: 5px;
  padding: 12px 20px;
  height: 46px;
  z-index: 99;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: -webkit-transform 0.15s ease-out;
  -o-transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;

  @media (max-width: 767px) {
    top: 70px;
    right: 10px;
    left: unset !important;
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    padding: 9px 12px;
  }

  & p {
    margin: 0 30px 0 15px;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: normal;

    @media (max-width: 767px) {
      margin: 0 10px 0 10px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  & .close {
    cursor: pointer;
  }
}

.popUpEntrada {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.sharedCountWrap {
  display: flex;
  align-items: center;
  margin-top: 24px;
  border-top: 1px solid #d1d1d1;
  padding-top: 5px;

  & p {
    display: inline-block;
  }
}

.sharedThumbCls {
  max-width: 100px;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;

  & .recentCls {
    margin-right: -10px;
    width: 32px;
    height: 32px;
    display: inline-block;

    & img {
      border-radius: 100px;
      overflow: hidden;
      object-fit: cover;
      background: #f00;
      border: 1px solid #fff;
      width: 32px;
      height: 32px;
    }

    &:first-of-type {
      margin-right: 0px;
    }
  }
}

.Linkcls {
  text-decoration: underline;
  cursor: pointer;
}

.avatarBase {
  /* Layout */
  width: 32px;
  height: 32px;

  @media screen and (max-width: 767px) {
    width: 32px;
    height: 32px;
  }

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  /* background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  ); */
  background: #e1d1ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #474747;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
}

.socialMedialinksBox {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px;
  margin-top: 24px;
  margin-bottom: 0;

  @media (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 10px;
    padding-bottom: 40px;
    padding-top: 30px;
    padding-right: 10px;
    max-width: calc(100vw - 13px);
    grid-gap: 8px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }
  }

  & li {
    font-style: normal;
    letter-spacing: -0.04em;
    color: #484848;
    cursor: pointer;
    transition: all ease 0.5s;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewportMedium) {
      max-width: 276px;
      width: calc(50% - 12px);
      border: 1px solid #d1d1d1;
      flex-direction: row;
      align-items: center;
      padding: 15px 20px;
      border-radius: 5px;
    }

    & button,
    & a {
      color: #484848;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (--viewportMedium) {
        flex-direction: row;
        align-items: center;
      }

      &:focus {
        outline: none;
      }
    }
  }

  & li {
    & button {
      border-radius: 5px;

      & svg {
        & path {
          fill: #000;
        }
      }

      & span {
        margin-left: 10px;
        color: #494949 !important;
        font-weight: 800 !important;
        @media (max-width: 767px) {
          margin-left: 0px;
          text-align: center;
        }
      }
    }

    & .copyButton {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      & span {
        margin-left: 10px;
        color: #494949 !important;
        font-weight: 800 !important;
        @media (max-width: 767px) {
          margin-left: 0px;
          text-align: center;
        }
      }
    }
  }
}
