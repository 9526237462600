@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.sliderImages {
  @media (max-width: 767px) {
    height: 100%;
    & > div {
      padding-bottom: 0% !important;
      height: 100%;
      & > div {
        position: relative !important;
        height: 100%;
        & img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

.noImage {
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 378px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  margin-top: -44px;

  @media (max-width: 1300px) {
    margin-top: 20px;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  position: relative;
  width: 60px;
  top: -82px;
  margin-top: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 148px;
    height: 148px;
    margin: auto;
    top: -85px;

    & > div {
      width: 100%;
      height: 100%;
    }
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */
  width: 150px;
  height: 150px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 24px;
  position: absolute;
  top: 0;
  left: 26%;

  @media (max-width: 1300px) {
    position: relative;
    left: unset;
    margin-top: -50px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 17px;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.25px;
  text-decoration-line: underline;
  text-transform: capitalize;

  color: #000000;
  margin: 0;
  border: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  & svg {
    margin-right: 10px;
  }

  @media (--viewportMedium) {
    line-height: 20px;
  }
}

.instagramIcon {
  margin-right: 12px;
  line-height: 20px;

  & svg {
    & path {
      fill: #212121;
    }
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionShare {
  display: none;

  @media (max-width: 768px) {
    display: block;
    text-align: right;
    margin-top: 20px;

    & .linkShare {
      margin-left: 21px;

      & svg {
        margin-right: 12px;
      }

      & span {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #000000;
      }
    }
  }
}

.descriptionTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
  margin-bottom: 19px;
  margin-top: 0;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #212121;
  font-family: 'Montserrat';
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionFaqs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .addFaqsHeading {
    font-weight: 400;
    background: none;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ed;
    box-shadow: none;

    &:hover {
      background: none;
      box-shadow: none;
    }
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.instagramGallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
  margin-top: 24px;

  & .galleryImages {
    & img {
      width: 100%;
    }
  }
}

.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.finishedModalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.swiperSlide {
  & > div {
    @media (max-width: 767px) {
      /* height: 295px !important; */
      & :global(.slick-slide) {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }

    & > div {
      height: 378px !important;

      & > div {
        @media (max-width: 460px) {
          /* height: 295px !important; */
        }
      }
    }
  }

  & img {
    /* padding: 4px 4px 0 0; */
  }

  & ul {
    display: flex !important;
    flex-direction: column;
    right: 0;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: unset;
    right: 0;
    top: 50%;
    transform: translate(-20px, -50%);
    gap: 13px;
  }

  & :global(.slick-thumb li.slick-active button::before) {
    background-color: var(--matterColorLight);
    content: '';
    width: 17px;
    height: 17px;
    background: #ffffff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
      0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 1;
  }

  & :global(.slick-thumb li button::before) {
    border: 1px solid #ffffff;
    content: '';
    width: 17px;
    opacity: 1;
    height: 17px;
    border-radius: 50%;
  }
}

.wishlistBox {
  display: none;

  @media (max-width: 768px) {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 0px 0px 20px 20px;
    width: 43px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 40px;
    display: block;
  }
}

.sectionImages {
  position: relative;
  & :global(.slick-track) {
    display: block !important;
  }
  & .socialShare {
    position: absolute;
    bottom: 0;
    right: 1px;
    background: rgba(33, 33, 33, 0.75);
    border-radius: 4px 4px 0;

    & li {
      & > div {
        cursor: pointer;
      }

      & span {
        display: none;
      }
    }

    @media (max-width: 768px) {
      top: 0;
      bottom: unset;
      background: #212121;
      width: 100%;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 4px;
    }

    & ul {
      display: flex;
      padding: 13px 35px;
      margin: 0;
      gap: 25px;

      & button {
        & svg {
          & path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.listingPageWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 0;
  }

  @media (min-width: 1440px) {
    gap: 40px;
  }

  @media (min-width: 1600px) {
    gap: 60px;
  }
}

.bookingPanelBox {
  width: 35%;

  @media (max-width: 1220px) {
    display: none;
  }

  @media (min-width: 1600px) {
    width: 509px;
  }
}

.mainWrapperLeft {
  width: 65%;
  overflow: hidden;

  @media (max-width: 1220px) {
    width: 100%;
  }

  @media (min-width: 1600px) {
    width: 961px;
  }
}

.maxSizePage {
  min-height: 100vh;
  padding: 0 30px;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (min-width: 1220px) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 0 30px; */
  }

  @media (min-width: 1600px) {
    max-width: 1530px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  /* 

  @media (max-width: 1336px) {
    max-width: 100%;
    padding: 0 60px;
  }

  @media (max-width: 1250px) {
    padding: 0 40px;
  }

  @media (max-width: 900px) {
    padding: 0 20px;
  } */
}

.bookingPanelSection {
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 30px;
  height: fit-content;
  overflow: auto;
}

.contentContainerBox {
  position: relative;
  background-color: #fff;
  padding: 0 30px 30px 30px;

  @media (max-width: 768px) {
    padding: 0 20px 20px 20px;
  }

  @media (min-width: 1440px) {
    padding: 0 62px 62px 62px;
  }
}

.topbarSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    display: none;
  }
}

.authorBox {
  padding-bottom: 7px;
  max-width: 650px;
  overflow-x: auto;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #212121;

  @media (max-width: 1300px) {
    max-width: 100%;
  }

  @media (max-width: 460px) {
    align-items: flex-start;
    gap: 18px;
  }

  @media (max-width: 768px) {
    /* flex-wrap: wrap; */
    justify-content: flex-start;
  }

  & a {
    color: #000000;
  }

  & span {
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #212121;
    border-radius: 100px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: 8px 20px;
    margin-right: 17px;
    align-items: center;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      height: 37px;
      margin-right: 0;
    }

    & svg {
      margin-right: 5px;
    }
  }
}

.authorBox::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* background-color: #aaa; or add it to the track */
}

/* .authorBox::-webkit-scrollbar-thumb {
  background: #AC6632;
} */

.dividerLine {
  margin-top: 35px;
  margin-bottom: 42px;
}

.SectionServices {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .addServicesHeading {
    font-weight: 400;
    background: none;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ed;
    box-shadow: none;

    &:hover {
      background: none;
      box-shadow: none;
    }
  }
}

.reviewBox {
  & .reviewList {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-top: 31px;

    & .reviewLeftBox {
      & img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .reviewRightBox {
      & .reviewHeadingName {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #212121;
        font-family: 'Montserrat';
        margin-top: 0;
        margin-bottom: 0;
      }

      & .reviewSubText {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #212121;
        margin-top: 0;
        margin-bottom: 0;

        & .ratingStar {
          margin-left: 10px;

          & svg {
            margin-right: 11px;
          }
        }
      }

      & .reviewDescription {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #595858;
        margin-top: 13px;
        margin-bottom: 0;
        font-family: 'Avenir LT Std', sans-serif;
      }
    }
  }
}

.locationLogistics {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.locationLogisticsDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-bottom: 19px;
  margin-top: 0;
}

.mapLogistics {
  width: 100%;
  max-width: 100%;
  max-height: 338px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  height: 338px;
  border-radius: 4px;
}

.operationTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-top: 0;
  margin-bottom: 0;
}

.operationFeatures {
  margin-top: 29px;
}

.weekNumber {
  color: #595858;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-top: 20px;
  margin-bottom: 0;
}

.entries {
  display: flex;
  flex-direction: column;
  border: 1px solid #d8dad9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 8px;
  cursor: pointer;
}

.socialUploadButton {
  background: #e9ecfa;
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 25px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.15em;
  color: #212121;
  margin-top: 9px;
  margin-bottom: 10px;

  &:hover {
    background: #e9ecfab0;
    box-shadow: none;
  }

  &:focus {
    background: #e9ecfab0;
    box-shadow: none;
  }
}

.dividerSocial {
  margin-top: 52px;
  margin-bottom: 42px;
}

.accordionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editButton {
  top: -6px;
  position: relative;
  right: 38px;
  cursor: pointer;
}

.leftBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    gap: 12px;
  }
}
.accordionDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accordionTitle {
  & img {
    width: 120px;
    height: 72px;
  }

  & h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #595858;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
  }
}

.accordanceBoxWraper {
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  padding: 27px 18px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 27px 7px;
    margin: 24px 0;
  }

  & .accordanceBox {
    border: none !important;
    padding: 0 !important;
  }
}

.faqBody {
  margin-top: 6px;
  display: flex;
  align-items: flex-start;
  /* flex-direction: row-reverse; */
  justify-content: flex-start;
  gap: 20px;

  & .faqEdit {
    cursor: pointer;
  }
}

.accordionList {
  text-align: left;
  width: 100%;
}

.accordionDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
}

details:last-child {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}

.accordanceBox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 14px;
  margin-bottom: 14px;

  &:after {
    content: '';
    background: red;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
  }
}


.accordanceBox[open] .accordionTitle {
  background-image: url('../../assets/close.png');
}

.accordanceBox .priceBoxWrap p {
  margin: 0;
}

.accordanceBox .priceBoxWrap p + p {
  margin-top: 0.5em;
}

.accordanceBox .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url('../../assets/open.png');
  background-repeat: no-repeat;
  background-position: right 0 top 1.625rem;
  background-size: 14px 9px;
  @media (max-width: 767px) {
    background-position: right 0 top 1.25rem;
  }
}

.accordanceBox .accordionTitle::marker::-webkit-details-marker {
  display: none;
}

.dividerLine {
  margin: 37px 0;
  @media (max-width: 767px) {
    margin: 17px 0;
  }
}
.serviceFormBox {
  @media screen and (min-width: 768px) {
    height: 75vh;
    overflow-y: auto;
    padding-right: 10px;
  }
}

.TripIdeaModal {
  & > div {
    & > div {
      border-radius: 25px !important;
      max-width: 450px;
      padding-bottom: 20px !important;
    }
  }
  & .tripModalContent {
    text-align: center;
    @media (max-width: 767px) {
      margin-top: 100px;
    }
    & p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & .profileImgModal {
    height: 120px;
    width: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -100px;
    & div {
      height: 120px;
    }
  }
}

.finishModal{
  & > div{
    & > div{
      @media (min-width: 768px) {
        flex-basis: 676px !important;
      }
    }
  }
}