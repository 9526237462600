.titleTop {
    display: flex;
    margin-bottom: 43px;
    align-items: center;
    justify-content: space-between;

    & h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.18px;
        color: #000000;
        font-family: 'Avenir LT Std', sans-serif;
        margin: 0;

        @media(max-width: 768px) {
            font-size: 18px;
        }
    }

    & .seeAll {
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #4661ED;
        font-family: 'Avenir LT Std', sans-serif;

        @media(max-width: 768px) {
            font-size: 14px;
        }
    }
}

.swiperSlide {
    /* & :global(.slick-slide)>div>div {
        padding: 0 47px;

        @media (max-width: 1500px) {
            padding: 0 22px;
        }

        @media (max-width: 920px) {
            padding: 0 10px;
        }

        @media (max-width: 600px) {
            padding: 0 50px;
        }

        @media (max-width: 460px) {
            padding: 0 10px;
        }
    } */

    & :global(.slick-slide) {
        padding: 0 26px;

        @media(max-width: 1440px) {
            padding: 0 15px;
        }

        @media(max-width: 768px) {
            padding: 0 10px;
        }

        @media(max-width: 600px) {
            padding: 0 5px;
        }
    }

    & :global(.slick-next):before,
    & :global(.slick-prev):before {
        content: unset;
    }

    & :global(.slick-arrow) {
        /* right: 40%; */
        top: unset;
        bottom: 44%;
    }

    & :global(.slick-next) {
        right: 11px;
    }

    & :global(.slick-prev) {
        left: 0;
        z-index: 5;
    }
}

.topVendorBox {}

/* .topVendorBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 53px;

    @media(max-width: 1440px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }

    @media(max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
} */

.topVendorCard {
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 20%);
    border-radius: 8px;
    position: relative;
    display: block;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.trendTop {
    display: flex;
    align-items: center;
    height: 102px;

    & .cardImage {
        width: 102px;
    }
}

.trendData {
    padding: 24px 18px;

    & h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.18px;
        color: #212121;
        margin: 0;
        font-family: 'Avenir LT Std', sans-serif;
    }

    & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.18px;
        color: #595858;
        margin: 0;
        font-family: 'Avenir LT Std', sans-serif;
    }
}