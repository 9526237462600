@import '../../styles/customMediaQueries.css';

.wrapperMain {
  padding: 55px 0;
  border: none;
  margin: 0 auto;
  max-width: 1564px;
  background-color: #3330;

  @media (max-width: 1440px) {
    max-width: 100%;
    padding: 30px;
  }

  @media (max-width: 1025px) {
    padding: 0;
    margin: 0;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 37px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  & .topbarBoxMain {
    display: none;

    @media (max-width: 1025px) {
      display: block;
      background: #0f0f0e;
      border-radius: 4px;
      padding: 10px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    & .headingText {
      margin: 0;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #ffffff;
      cursor: pointer;

      &:nth-child(1) {
        & svg {
          margin-right: 10px;
        }

        & .purchaseMoreLink {
          color: #f0eb6f;
          margin-left: 5px;
          text-decoration: underline;
        }
      }

      &:nth-child(2) {
        @media (max-width: 1025px) {
          display: none;
        }

        & svg {
          margin-left: 20px;
        }

        & .headingRightlink {
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }

  & .leftWrapper {
    background: #ffffff;
    border-radius: 4px;
    padding: 25px;
    width: 35%;
    max-width: 526px;
    height: fit-content;
    position: sticky;
    top: 140px;
    @media (max-width:1023px) {
      position: inherit;
    }
    &.submitProposalSelected{
    & .scrollLeftBox{
      max-height: 100%;
    }
    }
    @media (max-width: 768px) {
      padding: 20px;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    & h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #212121;
      margin-bottom: 20px;
      margin-top: 0;
    }

    & .scrollLeftBox {
      border-top: 1px solid #939393;
      padding-top: 20px;
      max-height: 550px;
      overflow-y: auto;
      padding-right: 20px;
      &::-webkit-scrollbar {
        width: 5px;
      }
  
      &::-webkit-scrollbar-track {
        background: #ededed;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #4661ed;
        border-radius: 100px;
      }
      & .topImage {
        & img {
          width: 100%;
        }
      }

      & .eventList {
        & .eventTitle {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #000000;
          margin-top: 20px;
        }
        & ul {
          & li {
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            color: #595858;

            & .boldText {
              font-weight: 500;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .subCategoryContainer {
    border-top: 1px solid #939393;
    padding: 13px 0 0 0;
  }

  & .rightWrapper {
    width: 65%;

    @media (max-width: 1024px) {
      width: 100%;
    }

    & .topbarBox {
      background: #0f0f0e;
      border-radius: 4px;
      padding: 10px 20px 10px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1300px) {
        flex-wrap: wrap;
        gap: 20px;
      }

      @media (max-width: 1025px) {
        display: none;
      }

      & .headingText {
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.05em;
        color: #ffffff;
        cursor: pointer;

        &:nth-child(1) {
          & svg {
            margin-left: 0px;
          }

          & .purchaseMoreLink {
            /* color: #F0EB6F; */
            margin-left: 5px;
            text-decoration: underline;
          }
        }

        &:nth-child(2) {
          @media (max-width: 1025px) {
            display: none;
          }

          & svg {
            margin-left: 20px;
          }

          & .headingRightlink {
            text-decoration: underline;
            color: #fff;
          }
        }
      }
    }
    & .tabs {
      display: flex;
      gap: 18px;
      align-items: center;

      @media (max-width: 767px) {
        & > div:not(:first-child) {
          display: none;
        }
      }
    }

    & .tab {
      cursor: pointer;
      border-radius: 10px 10px 0px 0px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      gap: 19px;
      color: #000;
      font-family: Lora;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.087px;
      background: rgba(255, 255, 255, 0.5);
      position: relative;

      @media (max-width: 1260px) {
        padding: 16px 8px;
      }
      @media (max-width: 767px) {
        gap: 11px;
        min-width: 224px;
      }
      & .dropdownIcon {
        position: absolute;
        top: 6px;
        right: 17px;
        height: 25px;
        width: 25px;
        cursor: pointer;
        & svg {
          fill: transparent;
        }
        @media (min-width: 767px) {
          display: none;
        }
      }
      & .count {
        color: #fff;
        width: 31px;
        height: 31px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4661ed;
        opacity: 0.75;
        @media (max-width: 767px) {
          width: 22px;
          height: 22px;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      & svg {
        & path {
          stroke: #4661ed;
        }
      }

      &.active {
        background: #4661ed;
        color: #fff;
        & svg {
          & path {
            stroke: #fff;
          }
        }
        & .count {
          opacity: 1;
          background: transparent;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
    & .eventRental {
      padding: 36px 40px 50px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 0px;

      @media (max-width: 768px) {
        padding: 20px;
        margin-top: 0;
      }

      & h3 {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: #212121;
        margin: 0 0 17px 0;
      }

      & .requirementBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 960px) {
          gap: 20px;
        }

        & .requirementSection {
          margin-right: 35px;

          & .icon {
            margin-right: 10px;
          }

          & span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.05em;
            color: #212121;
          }

          & .plannedLink {
            color: #4661ed;
            font-weight: 500;
            margin-left: 5px;
            text-decoration: underline;
          }

          & .boldText {
            font-weight: 500;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.finishedWrapperBox {
  text-align: center;

  & .headingFinished {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0.2em;
    color: #000000;
  }

  & .boldBoxSize {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0.12em;
    color: #000000;
  }

  & button {
    margin: 15px auto 15px auto;
    width: 376px;
  }
}

.EventStyleWrapper {
  & .EventStyleHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #939393;
    gap: 10px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & h4 {
      color: var(--Text, #212121);
      font-family: Lora;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.9px;
    }
  }
  & .eventColors {
    display: flex;
    align-items: center;
    gap: 11px;
    & .colorBox {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
}

:global(.slider-navs) {
  margin-top: 28px;
}

.singleImgSlider {
  margin-top: 40px;
  & :global(.slick-prev) {
    left: 0 !important;
    z-index: 1;
    &:before {
      content: '' !important;
      width: 29.071px;
      height: 36px;
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='32' viewBox='0 0 18 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5337 1.35742L1.25402 16.1447L16.0413 30.4244' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  & :global(.slick-next) {
    right: 0 !important;
    &:before {
      content: '' !important;
      width: 29.071px;
      height: 36px;
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='32' viewBox='0 0 18 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.94287 30.3088L16.7385 16.0378L2.4675 1.24216' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

.sliderNav {
  width: 66px;
  height: 66px;
  padding: 10px;
  & img {
    width: 100%;
    height: 100%;
  }
}

.sliderSingleImg {
  max-width: 356px;
  margin: 0 auto;
  display: block !important;
  & img {
    width: 100%;
  }
}

.pinterestLink {
  border-radius: 100px;
  border: 1px solid var(--Text, #212121);
  background: #fff;
  color: #21201f;
  text-align: center;
  font-family: Lora;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.16px;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 56px;

  &:hover {
    cursor: pointer;
    background: #f0eb6f;
  }
}

.styleDetails {
  margin-top: 26px;
  color: var(--Paragraph, #595858);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 237.5% */
  & b {
    font-weight: 500;
  }
}

.eventStyleInfo {
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid var(--Disabled-Text, #939393);
  background: #fff8f2;
  padding: 11px 23px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--Paragraph, #595858);
  font-family: Lora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.8px;
}

:global(.slick-track) {
  display: flex !important;
}

.dateInfo {
  display: flex;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
  }
}

.eventChatTab {
  margin-top: -9px;
}

.makeBid {
  & svg {
    & path {
      fill: #fff;
    }
  }
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  gap: 20px;
  & button {
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 41px;
    min-height: 41px;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.92px;
    letter-spacing: 0.01em;
    text-align: center;
    cursor: pointer;
  }
}

.reportBtn {
  font-family: Lora;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08749999850988388px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.confirmModelHeading {
  text-align: center;
  & p {
    margin: 0;
  }
}

.confirmButton {
  margin-top: 30px;
  & button {
    width: max-content;
    min-width: 200px;
    margin: 0 auto;
  }
}

.purchaseAcorns {
  max-height: 54px;
  padding: 7px 20px 7px 10px !important;

  & h4 {
    font-size: 14px !important;
    & svg {
      width: 44px;
      height: 44px;
    }
  }
}

.headerTitle{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #939393;

  & h2{
    margin-top: 0;
    font-size: 24px;
  }
}

.sliderContainer{
  margin-top: 20px;
}

.requirementSection{
  border-bottom: 1px solid #E0E0E0;
  padding: 22px 0;
  & a {
    color: #4661ed;
  }
}
