@import '../../styles/customMediaQueries.css';

.root {}

.menuIcon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.leftSidebar {
  background: #ffffff;
  border-radius: 4px;
  width: 30%;
  padding: 0px;
  /* @media (max-width: 1260px) {
    min-width: 300px;
  } */
  @media (max-width: 920px) {
    width: 100%;
  }
  @media (min-width: 767px) {
    min-width: 376px;
  }
  & .sidebarHeader {
    background: #f2f2fd;
    border-left: 16px solid #f0eb6f;
    padding: 12px;
    position: relative;
    @media (max-width: 767px) {
      background: #fff;
    }
    & .dropdownIcon {
      position: absolute;
      top: 6px;
      right: 22px;
      height: 25px;
      width: 25px;
      cursor: pointer;
      & svg {
        fill: transparent;
      }
      @media (min-width: 767px) {
        display: none;
      }
    }
    & p {
      margin: 0;
      color: #212121;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.25px;
    }
  }
  & .blueSidebarHeader {
    background: #f2f2fd;
  }
  & h3 {
    font-family: Montserrat;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #212121;
    margin-top: 0;
    margin-bottom: 0px;
  }

  & .eventList {
    padding: 0 0px 28px 0;
    overflow: auto;
    margin-top: 0px;

    @media (max-width: 920px) {
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .chatList {
    padding: 0 15px 28px 0;
    height: 575px;
    overflow: auto;
    margin-top: 30px;

    @media (max-width: 920px) {
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .chatUsers {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #e0e0e0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    cursor: pointer;
    padding: 20px 10px;
    border-radius: 0;
    align-items: flex-start;
    /* &:nth-child(1) {
      padding-top: 0;
    } */

    &:hover {
      background-color: #ffb16930;
    }

    & .userImage {
      position: relative;
      & > div {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        overflow: hidden;
        & > div {
          & > div {
            display: none;
          }
        }
        & svg {
          padding: 0;
          height: 35px;
          width: 35px;
        }
      }
      & img {
        width: 56px;
        height: 56px;

        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
        border-radius: 4px;
      }

      & .unreadCount {
        position: absolute;
        background: #4661ed;
        border: 1px solid #ffffff;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: -7px;
        bottom: 1px;
      }
    }

    & .chatData {
      & .userName {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #212121;
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #4b4b4b;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}

.messagesText {
  color: #4b4b4b;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 3px 0 3px;
}

.menuItem {
  padding: 0px 9px 0 8px;
  cursor: pointer;

  &:hover {
    color: #FFFFFF;
    background-color: #4661ed;;
  }
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.expandIcon{
  transform: rotate(180deg);
}