.root {}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-bottom: 24px;
  margin-top: 0;
}

.authLinksSignup {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    margin-top: 24px;
  }

  & a {
    font-weight: 700;
    color: #4661ED;
    margin-left: 5px;
    text-decoration: underline;

    @media(max-width: 460px) {
      display: block;
    }
  }
}