@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */
.skipBtn {
  max-width: fit-content;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 20px;
}

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
.title {
  display: flex;
  justify-content: space-between;
  line-break: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 16px;
}