.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

:global(#location-hero-slider) {
  & .media {
    height: 203px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 203px;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
    margin: auto;
    overflow: hidden;
  }
  & .text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(34, 29, 29, 0.45);
    margin: 0;
    & h3 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.12em;
      color: #ffffff;
      padding: 0 15px;
      text-align: center;
    }
    & a {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      opacity: 0;
      z-index: 8;
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
    }
  }
}

:global(#learn-more),
:global(#section-title) {
  & > div {
    & > div {
      & .text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        & p {
          width: 100%;
          max-width: 100%;
          font-family: 'Martel';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.18px;
          text-transform: capitalize;
          color: #000000;

          @media (max-width: 1024px) {
            margin-bottom: 15px;
          }
        }
        & a {
          background: #ffb169;
          border: 1px solid #ffffff;
          border-radius: 100px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 38px;
          text-align: center;
          letter-spacing: 0.15em;
          color: #000000;
          padding: 7px 20px;
          height: 51px;
          width: 296px;
          display: block;
          box-shadow: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

:global(#event-sizes) {
  & .media {
    width: 102px;
    height: 102px;

    border-radius: 8px 0px 0px 8px;
  }
  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #212121;
    margin: 0;
    font-family: 'Avenir LT Std', sans-serif;
  }
  & a {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    z-index: 8;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }
}

:global(#section-title-2) {
  & .media {
    max-height: 304px;
    overflow: hidden;
    & div {
      max-height: 304px;
    }
    & img {
      max-height: 304px;
    }
  }
  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #212121;
    margin: 0;
    font-family: 'Avenir LT Std', sans-serif;
  }

  & .text {
    margin-top: 90px;
    & p {
      font-family: 'Martel';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.18px;
      text-transform: capitalize;
      color: #000000;
      max-width: 100%;
      @media (max-width: 1024px) {
        margin-top: 30px;
      }
    }
    & a {
      background: #ffb169;
      border: 1px solid #ffffff;
      border-radius: 100px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.15em;
      color: #000000;
      padding: 7px 20px;
      height: 51px;
      width: 296px;
      display: block;
      margin: auto;
      margin-top: 34px;
      box-shadow: none;
    }
  }
}

:global(#learn-more) {
  & > div {
    & > div:last-child {
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      & > div {
        &:first-child {
          width: 100%;
          max-width: 775px;
        }
        &:last-child {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }
}

:global(#section-title) {
  & > div {
    & > div:last-child {
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      & > div {
        &:first-child {
          width: 100%;
          max-width: 400px;
        }
        &:last-child {
          max-width: 775px;
          width: 100%;
        }
      }
    }
  }
}