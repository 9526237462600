@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 12px 0 0; */

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (--viewportLarge) {
    /* padding: 0 24px 0 0; */
    height: 137px;
  }
}

/* logo */
.logoLink {
  /* border-bottom: 0px solid; */
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  /* padding: 21px 24px 21px 24px; */
  height: 100%;

  @media (--viewportLarge) {
    /* padding: 21px 36px 21px 36px; */
  }
}

.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor); */
  /* border-radius: 0; */
  /* text-decoration: none; */
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 97px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 265px;
  object-fit: contain;
  object-position: left center;

  @media (max-width: 1350px) {
    height: 60px;
  }
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.topbarDesktopLabel {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  /* composes: topbarDesktopLabel;
  position: relative; */
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.chatIcon {

  margin-right: 10px;
  position: relative;

  & .countNumber {
    position: absolute;
    height: 25px;
    width: 25px;
    background: #4661ed;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    bottom: -10px;
    right: -3px;
  }
}

.shopIcon {
  margin-left: 22px;
  margin-right: 18px;
}

.avatar {
  margin: 16px 0;
  height: 64px;
  width: 64px;
}

.downArrowProfile {
  margin-left: 7px;
}

.profileMenuContent {
  min-width: 276px !important;
  padding-top: 20px;
  right: 0 !important;
  left: unset !important;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: h5 from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #595858;
  position: relative;
  display: block;
  text-transform: capitalize;
  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    &:after {
      position: absolute;
      left: 0;
      background: var(--marketplaceColor);
      content: '';
      top: 0;
      bottom: 0;
      width: 6px;
    }

    /* & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;
  text-decoration: none;
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px 24px;
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px !important;
  letter-spacing: 0.05em;
  color: #595858;
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border: none;
  margin-top: 0 !important;

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.navMain {
  composes: marketplaceSectionWrapper from global;
  align-items: center;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1728px;
  padding: 0 43px 0 33px;

  @media (max-width: 1250px) {
    padding: 0 20px;
  }

  & .menuContainer {
    display: flex;
    justify-content: flex-end;
    /* gap: 15px; */

    @media (--viewportLargeWithPaddings) {
      gap: 15px;
    }

    @media (min-width: 1440px) {
      gap: 50px;
    }

    & .menuList {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;

      @media (--viewportLargeWithPaddings) {
        gap: 25px;
      }

      & .menuLink {
        border-bottom: 0px solid;
        transition: var(--transitionStyleButton);

        flex-shrink: 0;
        height: 100%;
        padding: 0 12px 0 12px;
        display: contents;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.12em;
        color: #4f4f4f;

        &:hover {
          text-decoration: none;
        }
      }

      & .activeLink {
        color: #ffb169;
      }
    }
  }

  & .beforeLoginButtons {
    display: flex;
    align-items: center;

    & .authButtonWrap {
      background: var(--matterColorLight);
      border: 1px solid var(--matterColorDark);
      border-radius: 100px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 32px;
      height: 56px;

      @media (max-width: 1350px) {
        padding: 0 14px;
        height: 40px;
      }

      &:hover {
        text-decoration: none;
      }

      & .signupText {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.12em;
        color: #21201f;
        position: relative;

        @media (max-width: 1350px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

.notSignUpBar {
  background-color: #ffb169;
  display: flex;
  align-items: center;
  padding: 21px 56px;
  justify-content: space-between;

  & .shareText {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.06em;
    color: #ffffff;
  }

  & .centerText {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 0.33em;
    color: #ffffff;

    & .matchLink {
      color: #ffffff;
      text-decoration: underline;
      margin: 0 10px;
    }
  }

  & .shareIcon {
    & svg {
      margin-left: 12px;
      cursor: pointer;
      width: 26px;
      height: 26px;

      & path {
        fill: #fff;
      }
    }
  }
}

.notificationDotWrapper {
  position: relative;
  display: inline-block;
}

.listItemsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.OfferMenuContent {
  min-width: 447px !important;
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 24px;
  border-top: 0;
  & > div{
    display: none;
  }
}

.offerDropwownHeader {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 31px;
  position: relative;
  margin-top: 10px;
  .clearBtn{
   position: absolute;
   right: 10px;
   top: -27px;
 
   cursor: pointer;
   font-size: 14px;
   font-weight: 600;
  }
  & h3 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}

.itemList {
  margin: 20px 17px 0;
  padding: 0;

  padding-right: 9px;
  max-height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #CBD4D9;
    background: #edf5fa;
  }

  &::-webkit-scrollbar-thumb {
    background: #004579;
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.seeAllMessage {
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  & svg{
    fill: transparent;
  }
  & *{
    color: #4661ed;
    text-align: center;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    text-decoration-line: underline;

  }
}

.listItem {
  /* Layout */

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #cbd4d9;
  padding: 12px 12px 12px 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  /* &:last-child {
    border-bottom: none;
  } */
  &:hover{
    text-decoration: none;
  }
  & .userImg {
    width: 41px;
    height: 41px;
    min-width: 41px;
    /* overflow: hidden; */
    border-radius: 50%;
    position: relative;
    & div{
      width: 41px;
      height: 41px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg{
        width: 20px;
        height: 20px;
        padding: 0;
      }
    }
    & .highlightMessage{
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: green;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  & img{
    width: 41px;
    height: 41px;
    overflow: hidden;
    border-radius: 50%;
  }
  & .userMsg {
    color: var(--Gray-4, #495057);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    & b {
      color: var(--Dark-Blue, #004579);
      font-weight: 600;
    }
  }
  @media (--viewportLarge) {
  }

  &:hover {
    background: #edf5fa;
  }
}
