.root {}

.requirementBox {
    /* display: flex;
    align-items: center; */
    /* border-top: 1px solid #939393;
    padding: 13px 0 18px 0;
    border-bottom: 1px solid #e0e0e0; */
    border-radius: 4px;
    background: #fff;
    padding: 22px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14);
    margin-bottom: 10px;
    &.active {
      background-color: rgb(244, 233, 223);
    }
    @media (max-width: 960px) {
      gap: 20px;
    }
  
    & .requirementSection {
      margin-right: 35px;
  
      & .icon {
        margin-right: 10px;
      }
  
      & span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #212121;
      }

      & .ChekcBoxItem{
        display: flex;
        align-items: flex-start;
        & b{
          line-height: 1.5;
        }
      }
  
      & .plannedLink {
        color: #4661ed;
        font-weight: 500;
        margin-left: 5px;
        text-decoration: underline;
      }
  
      & .boldText {
        font-weight: 500;
        margin-left: 5px;
        & span {
          font-weight: 500;
        }
      }
    }
  }


.CategoryBottomButton{
    display: flex;
    margin-bottom: 23px;
    gap: 20px;
    margin-top: 20px;
  
    @media (max-width:767px) {
      flex-direction: column;
    }
    & .yellowButton {
      background: #f0eb6f;
      border: 1px solid #212121;
      border-radius: 100px;
      font-family: 'Lora';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.12em;
      color: #000000;
      margin-bottom: 0px;

      &:disabled{
        background: #e0e0e0;
        opacity: 0.5;
      }

      & svg {
        margin-right: 5px;
      }
    }
  }