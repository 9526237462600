.root {}

.select {
  border: 1px solid #939393;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #212121;
  font-size: 16px;
  padding: 13px 17px;
  height: 45px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  /* border-bottom-color: var(--successColor); */
}

.selectError {
  border-bottom-color: var(--failColor);
}