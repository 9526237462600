@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1px;
}

.title,
.heading,
.subheading,
.timezonePicker,
.weekDay,
.hasEntries,
.submitButton {
  /* padding-left: 30px;
  padding-right: 30px; */

  @media (--viewportSmall) {
    /* padding-left: 60px;
    padding-right: 60px; */
  }
}

.subheading {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  font-weight: 600;
  /* padding-top: 19px;
  padding-bottom: 5px;
  color: var(--matterColorAnti); */
}

.error {
  color: var(--failColor);
}

.timezonePicker {
  margin-bottom: 24px;
}

.week {
  display: flex;
  flex-direction: column;

  padding-top: 16px;
  margin-bottom: 23px;

  @media (--viewportLarge) {
    margin-bottom: 35px;
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  /* Layout */
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 19px;
  border-top: solid 1px var(--matterColorNegative);

  &:last-child {
    border-bottom: solid 1px var(--matterColorNegative);
  }

  @media(max-width: 460px) {
    justify-content: space-between;
  }
}

.hasEntries {
  background-color: var(--matterColorLight);
}

.dayOfWeek {
  width: 180px;
  padding-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #212121;

  @media (--viewportSmall) {
    width: 200px;
  }
  @media (max-width: 560px){
    width: auto;
  }
}

.timePicker {
  width: 100%;

  @media(max-width: 460px) {
    width: unset;
  }

  & button {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    /* color: #212121; */
  }
}

.fieldWrapper {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--matterColorNegative);
    z-index: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* parent aka fieldWrapper is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;

  z-index: 1;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;

  stroke: none;
  fill: var(--matterColorAnti);

  &:hover {
    fill: var(--matterColor);
  }

  /* align icon with the select fields */
  padding: 2px 0 11px;
}

.dashBetweenTimes {
  padding-left: 6px;
  padding-right: 6px;
  /* padding-bottom: 8px; */
}

.buttonSetHours {
  padding-top: 8px;
}

.buttonAddNew {
  composes: h5 from global;
  color: var(--matterColor);
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: 0;
    height: 80vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .title {
    margin-bottom: 32px;
  }

  .buttonAddNew {
    margin-bottom: 6px;
  }

  .submitButton {
    padding-bottom: unset;

    & button {
      border-radius: 100px;
    }
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.fieldSelect {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  background: none;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 105px;
  border: none;
  text-align: center;
  padding: 0;

  &:select {
    margin-right: 0;
  }
}

.subheadingtimezonePickerLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 11px;
}

.timezonePickerSelect {
  margin-bottom: 40px;

  & select {
    padding: 0 17px;
    height: 45px;
  }
}