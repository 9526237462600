@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}


.dividerLine {
  border: 1px solid#E0E0E0;
  margin-top: 34px;
  margin-bottom: 23px;
  width: 100%;
}

.selectAllHeading {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 24px;

  @media(max-width: 500px) {
    padding-bottom: 24px;
  }
}

.categoriesBox {
  /* border-bottom: 1px solid #E0E0E0; */
  padding-bottom: 23px;

  /* margin-bottom: 34px; */
  & h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #212121;
  }

  & .categoriesCheckbox {
    color: red;

    & ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;

      @media(max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }

      @media(max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }

      & li {
        padding: 0;

        @media(max-width: 500px) {
          padding-bottom: 22px;
        }

        & span {
          @media(max-width: 500px) {
            margin-left: 0;
          }
        }

        & label {
          @media(max-width: 500px) {
            flex-direction: row-reverse;
            min-width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.submitButton {
  margin-top: 49px;
}