@import '../../styles/customMediaQueries.css';

.root {
}

.savedPaymentMethodTitle {
  composes: h4 from global;
  font-weight: bold;
  cursor: pointer;
}

.savedPaymentMethod {
  display: inline-block;
  margin-right: auto;
}

.paymentMethodPlaceholderDesktop {
  display: none;
}

.paymentMethodPlaceholderMobile {
  display: inline-block;
}

@media (--viewportSmall) {
  .paymentMethodPlaceholderDesktop {
    display: inline-block;
  }

  .paymentMethodPlaceholderMobile {
    display: none;
  }
}

.cardIcon {
  margin: 0 12px 0 4px;
  width: 24px;
  height: 16px;
  display: inline-block;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
  margin-left: 0;
  margin-right: 5px;
}

.iconCheckmark {
  stroke: var(--successColor);
  display: inline-block;
  margin: 12px 0 0 24px;
}

.iconCheckmarkHidden {
  visibility: hidden;
  display: inline-block;
  margin: 0 0 0 24px;
}

.expirationDate {
  padding-left: 24px;
}

.expirationDateExpired {
  padding-left: 24px;
  color: var(--failColor);
}

.cardExpiredText {
  composes: h4 from global;
  color: var(--failColor);
  font-weight: var(--fontWeightSemiBold);
  margin-top: 13px;
  margin-bottom: 11px;
}

.menu {
  max-width: 100%;
  height: auto;
}

.menuLabel {
  text-align: left;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);
  transition: all 0.2s ease-out;
  z-index: 9;
  position: relative;

  &:hover {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    text-decoration: none;
    border-radius: 4px 4px 0px 0px;
  }
}

.menuLabelWrapper,
.menuLabelWrapperExpired {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 24px 10px 16px;
}

.menuLabelWrapperExpired {
  border: 1px solid var(--failColor);
}

.menuContent {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowFilterButton);
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);

  transition: all 0.15s ease-out;

  /* When the dropdown is open, we need to hack the box-shadow's position
    to fit the whole dropdown menu component with padding-top

    MenuLabel height 56px + original padding-top 7px results to 63px
  */
  padding: 63px 0 15px 0;
  top: 0px;
}

.menuItem {
  color: var(--matterColor);
  &:hover {
    background-color: #f2f2f2;
  }
}

.menuText,
.menuTextReplaceCard {
  composes: marketplaceMessageFontStyles from global;
  padding: 12px 8px;
  color: var(--matterColor);
  text-align: left;
  font-weight: 500;
  width: 80%;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    background-color: #f2f2f2;
  }
}

.menuTextReplaceCard svg {
  position: relative;
  top: -2px;
  width: 22px;
  margin: 0 10px 0 4px;
}

.menuDivider {
  composes: h5 from global;
  color: var(--matterColorAnti);
  padding: 14px 24px 2px 24px;
  margin: 0;
}

.iconArrow {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.IconArrowAnimation {
  transform: rotate(180deg);
}

.savedPaymentMethodDelete {
  composes: h4 from global;
  color: var(--matterColorAnti);
  cursor: pointer;
  margin-top: 14px;
  margin-bottom: 10px;

  &:hover {
    color: var(--matterColor);
  }

  &:focus {
    outline: none;
    color: var(--matterColor);
  }
}

.modalButtonsWrapper {
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  padding-top: 36px;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  min-height: 200px;
}

.cancelCardDelete {
  color: var(--matterColorAnti);
  cursor: pointer;
  width: 50%;
  text-align: right;
  padding-right: 64px;

  &:hover {
    color: var(--matterColor);
  }
}
