@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;

  & input {
    padding: 12px;
  }

  @media(max-width: 620px) {
    width: 91%;
  }
}

.arrowIcon {
  position: absolute;
  right: 43px;
  top: 15px;
}

.submitButton {
  margin-top: auto;
  position: relative;
  /* margin-bottom: 24px; */

  & svg {
    @media(max-width:768px) {
      width: 20px;
      height: 20px;
    }
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 100%;
    margin-top: 46px;
  }
}

.priceBoxWrap {
  display: grid;
  grid-template-columns: 170px 170px auto;
  gap: 40px;
  margin-top: 19px;

  @media(max-width: 620px) {
    grid-template-columns: 100%;
    gap: 25px;
  }
}

.priceCheck {
  margin-top: 40px;

  @media(max-width: 620px) {
    margin-top: 0;
  }

  & label {
    margin-top: 0;

    @media(max-width: 620px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 0 !important;
    }

    & span {
      margin-left: 10px;

      @media(max-width: 620px) {
        margin-left: 0;
      }
    }
  }
}

.accordanceBox {
  margin-bottom: 38px;
}

.accordanceBox a {
  text-align: center;
  display: block;
  margin-top: 2em;
  color: #333;
}

.accordanceBox a:hover {
  color: #666;
}

.accordion {
  /* border: solid 2px #f5f5f5; */
  transition: all 0.3s ease-in-out;
  margin-top: 0.25rem;

  background: #FFFFFF;
  padding: 24px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}


.accordion:hover {
  /* background-color: #f5f5f5; */
}

.accordion[open] {
  /* background-color: #ebebeb;
  border-color: #ebebeb; */
}

.accordion[open] .accordionTitle {
  background-image: url("../../assets/uparrow.png");
}

.accordion .priceBoxWrap {
  /* padding: 0 1rem 1rem 1rem; */
}

.accordion .priceBoxWrap p {
  margin: 0;
}

.accordion .priceBoxWrap p+p {
  margin-top: 0.5em;
}

.accordion .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url("../../assets/downarrow.png");
  background-repeat: no-repeat;
  background-position: right 0.35rem top 0.625rem;
  background-size: 16px 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #212121;

  @media(--) {
    background-position: right 0.75rem top 0.625rem;
  }
}

.accordion .accordionTitle::marker::-webkit-details-marker {
  display: none;
}

.dividerLine {
  margin: 37px 0;
  /* border: 1px solid #E0E0E0; */
}

.requestMessage {
  margin-top: 26px;

  &>div {
    margin-bottom: 10px;

    &:nth-child(1) {
      margin-bottom: 0;
    }
  }

  &>div {
    &>div {
      & span {
        top: 3px !important;
        right: 0;
        cursor: pointer;
      }

      &:before {
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        background: #4661ED;
        top: 22px;
        border-radius: 50%;
      }
    }

    & input {
      border: none;
      border-bottom: 1px solid #00000061;
      border-radius: 0;
      margin-left: 26px;
      padding: 5px 35px 5px 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #939393;
      width: 80%;

      @media(--viewportMedium) {
        width: 90%;
      }
    }
  }
}

.deliveryCheck {
  margin-top: 28px;

  & label {
    justify-content: flex-end;

    & span {
      @media(max-width: 620px) {
        margin-left: 10px;
      }
    }
  }
}