@import '../../styles/customMediaQueries.css';

.wrapperMain {
  padding: 55px 0;
  border: none;
  margin: 0 auto;
  max-width: 1564px;
  background-color: #3330;

  @media (max-width: 1440px) {
    max-width: 100%;
    padding: 30px;
  }

  @media (max-width: 1025px) {
    padding: 0;
    margin: 0;
  }
}

.content {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}

.sectionHeader {
  & h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.12em;
    color: #000000;
    margin-bottom: 11px;
    margin-top: 0;
  }

  & .evenData {
    margin-bottom: 18px;

    & span {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #212121;
      margin: 0 10px;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  & .detailImageBox {
    & img {
      width: 100%;
      height: 206px;
      object-fit: cover;
    }
  }
}

.sectionDetailsEditBox {
  margin-top: 32px;

  & .editSectionTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #939393;
    padding-bottom: 19px;

    & h3 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #212121;
      margin: 0;
    }
  }

  & .editButton {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    text-decoration-line: underline;
    color: #4661ed;
  }

  & .eventsMapDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    @media (max-width: 576px) {
      flex-wrap: wrap;
    }
    & .eventsData {
      width: 50%;
      @media (max-width: 576px) {
        width: 100%;
      }
      & ul {
        margin: 0;

        & li {
          & .eventHeading {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            color: #595858;
            margin-right: 5px;
          }

          & .boldEventsName {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 38px;
            color: #595858;
          }
        }
      }
    }

    & .eventsMap {
      width: 50%;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 3px;
      & >div{
        height: 260px !important;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  & .sectionRequirement {
    & .editSectionTop {
      margin-top: 70px;
    }

    & .requirementList {
      margin-top: 30px;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      & .listName {
        background: #ffffff;
        border: 1px solid #212121;
        border-radius: 100px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        color: #212121;
        height: 38px;
        display: block;
        padding: 0 15px;
      }
    }
  }
}

.sectionEvent {
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px 22px;

  & .editSectionTopBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;

    & h3 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.05em;
      color: #212121;
      margin: 0;
    }
  }

  & .eventsTop {
    border-bottom: 1px solid #939393;
    padding-bottom: 14px;
    margin-bottom: 10px;

    & .eventsListName {
      & .eventHeading {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        color: #595858;
        margin-right: 5px;
      }

      & .textBold {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 38px;
        color: #595858;
      }
    }
  }

  & .eventsRequestedList {
    & ul {
      & li {
        & .vendorPass {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 38px;
          letter-spacing: 0.05em;
          color: #000000;
          position: relative;
          padding-left: 30px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            background-color: #4661ed;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            top: 1px;
          }
        }

        & .vendorSkip {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 38px;
          letter-spacing: 0.05em;
          text-decoration-line: line-through;
          color: #000000;
          position: relative;
          padding-left: 30px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            background-color: #595858;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            top: 1px;
          }
        }
      }
    }
  }
}

.eventMoodboard {
  & .editSectionTop {
    margin-top: 70px;

    & .moodboardHead {
      display: flex;
      gap: 13px;

      & .selectedColorCombination {
        & span {
          margin-left: 10px;
        }
      }
    }
  }

  & .moodboardBody {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    margin-top: 36px;
    margin-bottom: 72px;
    @media (max-width:576px) {
      flex-wrap: wrap;
      }
    & .bodyLeft {
      width: 50%;
      @media (max-width:576px) {
        width: 100%;
        }
      & .socialButton {
        margin-bottom: 26px;

        & .pinterestIcon {
          margin-right: 10px;
        }
      }

      & .eventBox {
        & .eventName {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 38px;
          color: #595858;
        }

        & .eventNameBold {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 38px;
          color: #595858;
        }
      }

      & .hintMessage {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: #595858;
        background: #fff8f2;
        border: 1px solid #939393;
        border-radius: 4px;
        width: 55%;
        padding: 11px 21px;
        margin-top: 20px;
        @media (max-width:576px) {
          width: 100%;
          word-wrap: break-word;
          }
        & .hintIcon {
          margin-right: 5px;
        }
      }
    }

    & .bodyRight {
      position: relative;
      width: 50%;
      @media (max-width:576px) {
        width: 100%;
        }
      & .sliderWrapper {
        & :global(.slick-prev:before) {
          display: none;
        }

        & :global(.slick-next:before) {
          display: none;
        }

        & :global(.slick-dots) {
          bottom: -41px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          gap: 25px;
        }
      }
    }
  }
}

.mainImage {
  width: 100%;
  height: 350px;
  margin: auto;
  object-fit: cover;
}

.smallImage {
  width: 43px;
  height: 43px;
  object-fit: cover;
}

.locationBox {
  width: 90%;
}
