@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 62px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.imagesField {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;

  @media(max-width: 920px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media(max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  & .thumbnail {
    height: 104px;

    @media(max-width: 920px) {
      width: 100%;
    }

    & label {
      height: 104px;
    }
  }
}

.thumbnail {
  /* height: 110px; */

  @media(max-width: 420px) {
    height: 100px;
  }

  &>div {
    &:nth-child(1) {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 100%;
    height: 201px !important;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: #EDEDED;

  @media (--viewportLarge) {
    /* background-color: var(--matterColorBright); */
  }

  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  text-align: center;
  letter-spacing: 0.12em;

  /* Paragraph */

  color: #595858;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    /* margin-top: 0;
    margin-bottom: 0; */
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  text-align: center;
  letter-spacing: 0.12em;

  /* Paragraph */
  font-style: italic;
  color: #595858;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.plannerBusinessForm {}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media(max-width: 600px) {
    flex-wrap: wrap;
    gap: 26px;
  }

  @media (--viewportMedium) {
    width: 100%;
    flex-wrap: wrap;
  }
}

.dividerLine {
  border: 1px solid#E0E0E0;
  margin-top: 39px;
  margin-bottom: 26px;
}

.professionalBioRoot {
  &>div {
    &:nth-of-type(1) {
      background: #FFFFFF;
      border: 1px solid #939393;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  & textarea {
    height: 104px !important;
    overflow-y: auto !important;
    padding: 0;
    border: none;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid rgb(255 30 30 / 0%);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #939393;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background: #EDEDED;
  border-radius: 10px;
  margin-right: 20px;
}

.inputBoxWebSite {
  margin-top: 24px;

  & input {
    padding: 12px 21px;
  }

  & textarea {
    border: none;
  }
}

.inputBox {
  & input {
    padding: 12px 21px;
  }

  & textarea {
    padding: 12px 21px;
  }
}

.socialInput {
  width: calc(50% - 9px);

  @media(max-width: 600px) {
    width: 100%;
  }

  & input {
    padding: 12px 21px 12px 55px;
  }
}

.eventTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 20px;
}

.fullWidthWrapper {}

.fullWidthField {
  & .thumbnail {
    height: 156px;
  }
}

.imagesField {
  & .chooseImage {
    display: none;
  }

  & .imageTypes {
    display: none;
  }
}

.infoTooltip {
  position: relative;
  cursor: pointer;
  & .showTooltip {
    width: 185px;
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -15px;
    visibility: hidden;
    left: 105%;
    font-size: 10px;
    border: 1px solid #ddd;
  }
  &:hover {
    & .showTooltip {
      visibility: visible;
    }
  }
}