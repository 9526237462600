.sectionBlog {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 37px;
}

.blogCard {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    & .blogTop {
        & img {
            width: 100%;
            border-radius: 4px;
        }
    }

    & .blogBody {
        padding-top: 21px;

        & .categoriesText {
            font-weight: 400;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0.18px;
            color: #595858;
            font-family: 'Avenir LT Std', sans-serif;
            margin: 0 0 8px 0;
        }

        & h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.18px;
            text-transform: capitalize;
            color: #000000;
            font-family: 'Avenir LT Std', sans-serif;
            margin: 0 0 35px 0;
        }
    }
}

.submitButton {
    width: 206px;
}