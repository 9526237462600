@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    /* transform: scale(1.02); */
    /* box-shadow: var(--boxShadowListingCard); */
  }
}

.city {
  font-size: 14px;
  line-height: 20px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.tredingFoodBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 95px;
}

.trendTop {
  position: relative;

  & > div {
    border-radius: 8px 8px 0 0;
  }

  & .cardImage {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: 167px;
  }

  & .brandLogo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
      width: 123px;
      height: 123px;
    }
  }
}

.trendCard {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-decoration: none;
  margin-top: 64px;

  &:hover {
    text-decoration: none;
  }
}

.trendingBody {
  padding: 14px;
  text-align: center;

  & h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #595858;
    margin: 0;
    min-height: 24px;
  }

  & .categoriesText {
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & .categoriesTextWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .city {
      font-size: 14px;
    }
    & p{
      min-height: 32px;
      margin: 0;
    }

    & span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      color: #595858;

      & svg {
        margin: 0 5px;

        & path {
          fill: #212121;
        }
      }
    }
  }

  & .addList {
    margin-top: 8px;
    position: relative;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: 0.15em;
    text-decoration: none;
    color: #000000;
    background: none;
    min-height: unset;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.titleTop {
  display: flex;
  margin-bottom: 98px;
  align-items: center;
  justify-content: space-between;

  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #000000;
    font-family: 'Avenir LT Std', sans-serif;
    margin: 0;
  }

  & .seeAll {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ed;
    font-family: 'Avenir LT Std', sans-serif;
  }
}

.submitButton {
  font-family: 'Montserrat' !important;
  font-weight: 400;
  letter-spacing: 0.15em;
}

.logoBrand {
  border-radius: 50%;

  & > div {
    /* background-color: green; */
    width: 123px;
    height: 123px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    & img {
      border-radius: 50%;
    }
  }
}

.viewDetailButton {
  margin-top: 16px;
  position: relative;
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: 0.15em;
  background-color: #212121;
  color: var(--matterColorLight);
  min-height: 56px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--matterColorLight);
  }
}