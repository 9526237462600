.tredingFoodBox {
  /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 95px;

    @media(max-width: 1680px) {
        gap: 50px;
    }

    @media(max-width: 1440px) {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        gap: 40px;
        padding-top: 67px;
        padding-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;

        &::-webkit-scrollbar {
            display: none;
        }

        & {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    @media(max-width: 860px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    } */

  & :global(.slick-slider) {
    max-width: 1581px;
  }

  & :global(.slick-list) {
    padding-top: 70px;
    padding-bottom: 10px;
  }
  & :global(.slick-track) {
    display: flex !important;

}
  & :global(.slick-slide) {
    height: auto !important;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
}

.trendTop {
  position: relative;

  & > div {
    border-radius: 8px 8px 0 0;
  }

  & .cardImage {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: 167px;
  }

  & .brandLogo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
      width: 123px;
      height: 123px;
      background: #fff;
    }
  }
}

.sliderCard {
  padding-right: 95px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding-right: 35px;
  }
}

.trendCard {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1440px) {
    min-width: 30%;
    width: 100%;
  }

  @media (max-width: 960px) {
    min-width: 38%;
    width: 100%;
  }

  @media (max-width: 768px) {
    min-width: 40%;
    width: 100%;
  }

  @media (max-width: 680px) {
    min-width: 55%;
    width: 100%;
  }

  @media (max-width: 520px) {
    min-width: 65%;
    width: 100%;
  }

  @media (max-width: 460px) {
    min-width: 75%;
    width: 100%;
  }

  @media (max-width: 414px) {
    min-width: 85%;
    width: 100%;
  }
}

.trendingBody {
  padding: 14px;
  text-align: center;

  & h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #595858;
    margin: 0;
    min-height: 24px;
  }

  & .categoriesText {
    margin-bottom: 10px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & .categoriesTextWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .city {
      font-size: 14px;
    }
    & p{
      min-height: 32px;
      margin: 0;
    }

    & span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      color: #595858;

      & svg {
        margin: 0 5px;

        & path {
          fill: #212121;
        }
      }
    }
  }

  & .addList {
    margin-top: 8px;
    position: relative;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: 0.15em;
    text-decoration: none;
    color: #000000;
    background: none;
    min-height: unset;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.titleTop {
  display: flex;
  margin-bottom: 42px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1440px) {
    margin-bottom: 42px;
  }

  & h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #000000;
    font-family: 'Avenir LT Std', sans-serif;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: normal;
    }
  }

  & .seeAll {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #4661ed;
    font-family: 'Avenir LT Std', sans-serif;

    @media (max-width: 768px) {
      font-size: 14px;
      width: 55px;
      display: block;
    }
  }
}

.submitButton {
  font-family: 'Montserrat' !important;
  font-weight: 400;
  letter-spacing: 0.15em;
}

.viewDetailButton {
  margin: 10px 0;
  position: relative;
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: 0.15em;
  background-color: #212121;
  color: var(--matterColorLight);
  min-height: 56px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.logoBrand {
  border-radius: 50%;

  & > div {
    /* background-color: green; */
    width: 123px;
    height: 123px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    & img {
      border-radius: 50%;
    }
  }
}

.swiperSlide {
  & :global(.slick-slide) > div > div {
    padding: 0 47px;

    @media (max-width: 1500px) {
      padding: 0 22px;
    }

    @media (max-width: 920px) {
      padding: 0 10px;
    }

    @media (max-width: 600px) {
      padding: 0 50px;
    }

    @media (max-width: 460px) {
      padding: 0 10px;
    }
  }

  & :global(.slick-next):before,
  & :global(.slick-prev):before {
    content: unset;
  }

  & :global(.slick-arrow) {
    /* right: 40%; */
    top: unset;
    bottom: 44%;
  }

  & :global(.slick-next) {
    right: 11px;
  }

  & :global(.slick-prev) {
    left: 0;
    z-index: 5;
  }
}
