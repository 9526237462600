.root {}

.input {
  border: 1px solid #939393;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #212121;
  font-size: 16px;
  padding: 13px 17px;
  height: 45px;
}

label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  padding: 0;
  margin-bottom: 14px;
}

/* .inputSuccess {
  border-bottom-color: var(--successColor);
} */

.inputError {
  /* border-bottom-color: var(--failColor); */
}

.textarea {
  box-sizing: border-box;
  min-height: 80px;
}

.inputForm {
  position: relative;

  & .inputIcon {
    position: absolute;
    transform: translate(9px, 5px);
    z-index: 1;
    top: 0;

    @media(max-width: 768px) {
      transform: translate(9px, 10px);
    }
  }
}