

:global(#location-hero-slider),
:global(#event-sizes-slider) {
overflow: inherit !important;
width: 100%;
}


.swiperSlide {
    @media(max-width: 1024px) {
        padding: 0 100px;
    }

    @media(max-width: 620px) {
        padding: 0 50px;
    }

    & :global(.slick-list) {
       max-width: 1530px;
       @media (max-width: 1530px) {
        max-width: calc(100vw - 40px);
       }
    }

    & :global(.slick-slide)>div>div {
        /* padding: 0 25px; */

        @media (max-width: 620px) {
            padding: 0 10px;
        }
    }

    & :global(.slick-next):before,
    & :global(.slick-prev):before {
        content: unset;
    }

    & :global(.slick-arrow) {
        /* right: 40%; */
        top: unset;
        bottom: 44%;
    }

    & :global(.slick-next) {
        right: -25px;

        @media(max-width: 1024px) {
            right: 26px;
        }
    }

    & :global(.slick-prev) {
        left: -36px;
        z-index: 5;

        @media(max-width: 1024px) {
            left: 26px;
        }
    }
}
