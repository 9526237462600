.accordion {
    margin-bottom: 1rem;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 1rem;
    cursor: pointer;
  }
  
  .header:hover {
    background-color: #e0e0e0;
  }
  
  .icon {
    transition: transform 0.3s;
  }
  
  .iconOpen {
    transform: rotate(180deg);
  }
  
  .content {
    background-color: #f9f9f9;
    padding: 1rem;
  }
  