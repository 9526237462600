.root {}

.headingName {
  text-align: center;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.2em;
  color: #000000;
  margin-bottom: 26px;
}

.sendLink {
  margin-top: 9px;
  text-align: right;

  & a {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    text-decoration-line: underline;
    color: #4661ED;
  }
}