
@import '../../styles/customMediaQueries.css';

.root {
  max-height: 600px;
  padding-right: 10px;
}

details {
  background: #FFFFFF;
  border: 1px solid #939393 !important;
  border-radius: 4px;
  padding: 12px 21px !important;

  & .accordionDetail {
    & h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #595858;
      margin: 0;
    }
  }
}

.accordanceBox[open] {
  background: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px 22px;

  & .accordionDetail {
    & h4 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.05em;
      color: #212121;
      margin: 0;
    }
  }
}

.accordanceBox[open] .accordionTitle {
  background-image: url("../../assets/uparrow.png");
}

.accordanceBox .priceBoxWrap p {
  margin: 0;
}

.accordanceBox .priceBoxWrap p+p {
  margin-top: 0.5em;
}

.accordanceBox .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url("../../assets/downarrow.png");
  background-repeat: no-repeat;
  background-position: right 0px top 0.425rem;
  background-size: 18px 10px;

}

.accordanceBox .accordionTitle::marker::-webkit-details-marker {
  display: none;
}

.bottomWrapper {
  margin-top: 44px;
}

.budgetRow {
  display: flex;
  justify-content: space-between;
  width: 85%;
  gap: 53px;

  & .inputBox {
    width: calc(44vh - -15px);

    & input {
      padding: 0 17px !important;
    }
  }
}

.addVendorBox {
  margin-top: 19px;
}

.requirementRequest {
  margin-top: 25px;
  width: 97%;

  & p {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #595858;
    margin: 0;
  }
}

.requestBox {
  margin-top: 20px;

  & .requestInput {
    display: flex;
    position: relative;

    & .requestDot {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    & input {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.38);
      border-radius: 0;
      font-family: 'Avenir LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.6);
    }

    & .addClose {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 7px;
    }
  }
}

.headingName {
  text-align: center;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.2em;
  color: #000000;
  margin-bottom: 26px;
}

.dividerLine {
  margin: 20px 0;
}

.priceInput {
  width: 70%;
  margin-top: 20px;
}

.title {
  width: 70%;
  margin-top: 20px;
}

.priceCheck {
  margin-top: 20px;
  display: block;
}

.requestMessage {
  & input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  }

  &>div {
    &>div {
      & span {
        top: 16px;
        right: -45px;
      }
    }
  }
}