.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

:global(#terms-of-service),
:global(#privacy-page) {
  & > div {
    padding-top: 0;
  }
}

.layoutSidebarPage {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & .sidebar {
    background: #f4e9df;
    border-right: 1px solid #000;
    padding: 60px 0 82px 20px;
    @media (min-width: 1128px) {
      padding: 60px 0 82px calc((100% - 1056px) / 2);
    }
    @media (max-width: 767px) {
      padding: 20px 20px 20px 20px;
    }
  }
  & .sidebarContent {
    display: flex;
    flex-direction: column;
    & a {
      font-weight: 500;
      font-size: 18px;
      line-height: 56px;
      width: var(--TabNav_linkWidth);
      display: inline-block;
      white-space: nowrap;
      letter-spacing: 0;
      color: var(--matterColor);
      transition: var(--transitionStyleButton);
      background-position: calc(var(--TabNav_linkWidth) + 4px) center;
      &.activeLink{
        font-weight: 700;
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 36px;
      }
    }
  }
}

.contentWrapper {
  & header {
    display: block;
    text-align: left;
    padding: 0;
    & h2 {
      text-align: left;
      max-width: 100%;
      display: block;
      width: 100%;
      margin: 0px 0 24px 0;
      @media (min-width: 768px) {
        font-size: 40px;
        font-weight: var(--fontWeightSemiBold);
        line-height: 48px;
        letter-spacing: -1px;
      }
    }
  }
  & > div{
    padding: 0;
  }
  padding: 30px 20px 82px 20px;
  @media (min-width: 1128px) {
    padding: 85px calc((100% - 1056px) / 2) 82px 59px;
  }
  @media (max-width: 767px) {
    padding: 30px 20px 82px 20px;
  }
}

:global(#contact-info){
  & h5{
    font-weight: 400;
    border-top: 1px solid #000;
    padding-top: 20px;
    margin-top: 20px;
    font-size: 15px;
  }
}
