@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
}

.heroContent {
  background-image: url(../../assets/background-1440.jpg);
  background-size: cover;
  position: relative;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    background-size: cover;
    height: 437px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.heroHeading {
  z-index: 9;
  position: relative;
  padding-left: 47px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  & h1 {
    font-weight: 400;
    font-size: 33px;
    line-height: 56px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 9px;

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  & p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #ffffff;
    margin: 0;
    font-family: 'Avenir LT Std', sans-serif;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.mainCover {
  width: 70%;
  margin: 0 auto;
  z-index: 3;

  @media (max-width: 1440px) {
    width: 90%;
  }
  @media (max-width: 767px) {
    z-index: 6;
  }
  & h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 73px;
    margin-top: 0;
  }
}

.searchBar {
  z-index: 9;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 80px;
  justify-content: space-between;

  @media (max-width: 920px) {
    background-color: #fff0;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  @media (max-width: 767px) {

    gap: 20px;
  }
  & .searchWrapper {
    width: 100%;
    display: flex;
  }
  & .searchLeft {
    display: flex;
    align-items: center;
    gap: 36px;
    width: 100%;
    position: relative;
    @media (max-width: 920px) {
      flex-wrap: wrap;
    }
  
    @media (max-width: 767px) {

      gap: 20px;
    }
    & .locationDropDown {
      position: relative;

      @media (max-width: 920px) {
        width: 100%;
      }

      & :global(.ais-MenuSelect){
        background-image: url("data:image/svg+xml,%3Csvg class='LocationAutocompleteInput_iconSvg__uTJlN' width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1109 23.4C12.5156 20.3906 18 13.0969 18 9C18 4.03125 13.9688 0 9 0C4.03125 0 0 4.03125 0 9C0 13.0969 5.48438 20.3906 7.88906 23.4C8.46562 24.1172 9.53438 24.1172 10.1109 23.4ZM9 12C7.34531 12 6 10.6547 6 9C6 7.34531 7.34531 6 9 6C10.6547 6 12 7.34531 12 9C12 10.6547 10.6547 12 9 12Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 27px 47%;
      }

      & :global(.ais-MenuSelect-select) {
        border: 0;
        background-size: 26px;
        margin-left: 28px;
        padding-right: 10px;
        background-image: url("data:image/svg+xml, %3Csvg width='26' height='15' viewBox='0 0 26 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.69708 1.28925L13.0672 13.9353L24.399 1.25482' fill='none' stroke='%23111010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      }
      & .downArrow {
        position: absolute;
        right: 13px;
        top: 32px;

        @media (max-width: 1440px) {
          right: 20px;
          top: 22px;
        }
        &.open {
          transform: rotate(178deg);
        }
        &.close {
        }
      }
    }
  }

  & .searchForm {
    width: 340px;
    height: 102px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 20px;
    @media (max-width: 1440px) {
      height: 80px;
    }

    @media (max-width: 920px) {
      height: 71px;
      width: 100%;
    }

    & > div {
      padding-left: 28px;

      @media (max-width: 920px) {
        width: 100%;
      }
    }

    & input {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.15em;
      color: #111010;

      @media (max-width: 920px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  & .keyWordBox {
    position: relative;
    width: 100%;
    display: flex;

    & input ~ div{
      display: none;
    }
    & .searchIcon {
      position: absolute;
      left: 0;
      top: 8px;

      @media (max-width: 920px) {
        top: 18px;
        left: 20px;
      }

      & svg {
        width: 41px;
        height: 39px;

        @media (max-width: 1440px) {
          height: 30px;
          width: 25px;
        }
      }
    }

    & .searchKeyword {
      border: none;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.12em;
      padding: 14px 57px;

      @media (max-width: 1440px) {
        width: 100%;
      }

      @media (max-width: 920px) {
        background: #ffffff;
        border-radius: 80px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 71px;
        font-size: 16px;
        line-height: 18px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  & .goButton {
    background: #ffb169;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 28px 31px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #fff;
    margin-right: 3px;
    border: 1px solid #ffb169;
    cursor: pointer;
    width: max-content;

    &:hover {
      background-color: var(--primaryButtonColor);
      border: 1px solid var(--primaryButtonColor);
    }

    @media (max-width: 1440px) {
      line-height: 14px;
    }

    @media (max-width: 920px) {
      width: 100%;
      height: 71px;
    }
  }

  & .goFullButton {
    width: 100%;
    height: 71px;
    background: #ffb169;
    border: 1px solid #ffb169;
    border-radius: 100px;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #fff;

    &:hover {
      background-color: var(--primaryButtonColor);
      border: 1px solid var(--primaryButtonColor);
    }
  }
}

.categoryBoxCard {
}

.heroCategories {
  display: flex;
  align-items: center;
  gap: 24px;
  color: unset;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    min-width: 80%;
    width: 100%;
  }

  @media (max-width: 560px) {
    min-width: 100%;
    width: 100%;
    gap: 10px;
  }

  & .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c5c4c4;
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 50%;
    flex-wrap: wrap;
    font-size: 12px;
  }

  & .categoriesImage {
    & .logoBrand {
      border-radius: 50%;

      & > div {
        width: 72px;
        height: 72px;
        border-radius: 50%;

        & img {
          border-radius: 50%;
        }
      }
    }
  }

  & .categoriesText {
    & h4 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-family: 'Avenir LT Std', sans-serif;
      letter-spacing: 0.15px;
      color: #212121;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #595858;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.heroCategoriesBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 39px;
  margin-top: 30px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    padding-bottom: 10px;
  }

  /* 
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */
}

.backgroundImage {
  background-image: url(../../assets/background-1440.jpg);
  height: 599px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 56px;

  &::after {
    content: '';
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  & .backgroundUpper {
    z-index: 9;

    & h1 {
      font-family: 'Martel Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      margin: 0;
    }

    & .searchBarBox {
      margin-top: 73px;

      & .searchForm {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 80px;

        & > div {
          height: 102px;
          display: flex;
          align-items: center;

          & > div {
            /* height: 33%; */
            margin-top: 26px;
          }
        }
      }
    }
  }
}

.searchBarBox {
  position: relative;

  & .buttonRight {
    position: absolute;
    right: 6px;
    top: 6px;

    & button {
      background: #ffb169;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      font-family: 'Lora';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.15em;
      color: #ffffff;
      padding: 26px;
      height: 91px;
      width: 91px;
      border: 1px solid #ffb169;
      cursor: pointer;
    }
  }
}

.swiperSlide {
  @media (max-width: 1024px) {
    padding: 0 100px;
  }

  @media (max-width: 620px) {
    padding: 0 50px;
  }

  & .roundIcon {
  }

  & :global(.slick-slide) > div > div {
    /* padding: 0 25px; */

    @media (max-width: 620px) {
      padding: 0 10px;
    }

    /* @media (max-width: 920px) {
            padding: 0 10px;
        }

        @media (max-width: 600px) {
            padding: 0 50px;
        }

        @media (max-width: 460px) {
            padding: 0 10px;
        } */
  }

  & :global(.slick-next):before,
  & :global(.slick-prev):before {
    content: unset;
  }

  & :global(.slick-arrow) {
    /* right: 40%; */
    top: unset;
    bottom: 44%;
  }

  & :global(.slick-next) {
    right: -25px;

    @media (max-width: 1024px) {
      right: 26px;
    }
  }

  & :global(.slick-prev) {
    left: -36px;
    z-index: 5;

    @media (max-width: 1024px) {
      left: 26px;
    }
  }
}

.imageBack {
  /* position: relative; */
  /* height: 203px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  cursor: pointer;

  & .roundIcon {
    height: 203px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 203px;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;
    margin: auto;
    background-image: url(../../assets/flower.png);

    @media (max-width: 768px) {
      margin: auto;
      height: 150px;
      width: 150px;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(34, 29, 29, 0.45);
      top: 0;
      border-radius: 50%;
    }

    /* position: absolute; */

    /* & img {
          border-radius: 50%;
          width: 203px;
          height: 203px;
          object-fit: cover;

          @media(max-width: 768px) {
              width: 160px;
              height: 160px;
          }
      }

      & .shadeBox {
          position: absolute;
          height: 100%;
          width: 100%;
          background: rgba(34, 29, 29, 0.45);
          top: 0;
          border-radius: 50%;
      } */
  }

  & .textName {
    z-index: 9;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.12em;
    color: #ffffff;
    padding: 0 15px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.coverSlider {
  padding: 30px 0 30px 0;
}

.categoriesLink {
  &:hover {
    text-decoration: none;
  }
}

/* categoryDropDown */
.categoryDropDown {
  position: absolute;
  left: 10px;
  right: -10px;
  top: 101%;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  padding: 22px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14);
  z-index: 4;
  @media (max-width: 767px) {
    top: 100%;
    max-height: 280px;
    overflow-y: auto;
    
  }
}

.mobileSubcategories{
  padding-left: 10px;
  margin-bottom: 10px;

  & p{
    margin-bottom: 8px;
  }
  & li{
    padding-left: 0;
  }
}

.activeCategory {
  position: relative;
  margin: 8px 0;

  &::after {
    content: '';
    position: absolute;
    border: 1px solid #000;
    border-radius: 15px;
    top: -3px;
    right: -10px;
    left: -10px;
    bottom: -3px;
    border: 1px solid #000;
    border-radius: 15px;
  }
}

.categoriesTitle {
  display: flex;
  gap: 55px;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    gap: 20px;
  }
  & p {
    min-width: 214px;
    color: var(--Neutral-Dark, #91929d);

    /* Body/Regular */
    font-family: Lora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin: 0;
  }
}

.categoriesWrapper {
  display: flex;
  gap: 80px;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    gap: 20px;
    flex-direction: column;
  }
  & li {
    color: #23263b;
    font-family: Lora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 128.571% */
  }
  & .mainCategories {
    width: 190px;
  }
  & .Subcategories {
    flex: 1;
    & .categoriesItem {
      display: flex;
      flex-wrap: wrap;
      & li {
        max-height: 33%;
        min-width: 33%;
        max-width: 33%;
        flex: 1;

        @media (max-width: 767px) {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }
  & .mainCategoriesItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    cursor: pointer;
  }
}

.stepsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0px;
  border-radius: 0px;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
}

.step {
  flex: 1;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 0px;
  background-color: #636363;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1023px) {
    width: calc(50% - 10px);
    flex: calc(50% - 10px);
    padding: 20px 10px;
    flex-direction: column;
  }
}

.stepNumber {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #333;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0px;
  @media (max-width: 1023px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 18px;
  }
}

.stepDescription {
  font-size: 18px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.step:nth-child(2) {
  background-color: #494949;
  & .stepNumber {
    background-color: #fde1c3;
  }
}

.step:nth-child(3) {
  background-color: #414141;
  & .stepNumber {
    background-color: #ffce9c;
  }
}

.step:nth-child(4) {
  background-color: #373839;
  & .stepNumber {
    background-color: #ffb06a;
  }
}
