@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media(max-width: 600px) {}

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 49px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.planCheckBox {
  & ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media(max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    & li {
      padding: 0;
    }
  }
}

.titleName {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 24px;
  @media(max-width: 460px) {
    margin-bottom: 29px;
  }
}

.dividerLine {
  border: 1px solid#E0E0E0;
  margin-top: 37px;
  margin-bottom: 29px;
  width: 100%;
}

.checkboxList {
  & ul {
    & li {
      padding: 0;
      margin-bottom: 24px;
    }
  }
}

.features {
  & ul {
    & li {
      & label {
        margin: 0;
      }
    }
  }
}