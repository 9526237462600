@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 16px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 40px;

  & button {
    border-radius: 100px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 19px;
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.multiselectInput {
  margin-bottom: 36px;
  margin-top: 30px;
}

.exactLocationText {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4661ed;
  margin-top: 15px;
  margin-bottom: 32px;
}

.workcheckBox {
  margin-top: 32px;
  margin-bottom: 21px;
}

.inputBox {
  & > div {
    & > div {
      & > div {
        border-bottom: 0;
        position: absolute;

        & svg {
          display: none;
        }
      }
    }
  }
}

.listServiceHeading {
  text-align: center;
}

.imageUploadWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 35px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    gap: 15px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.addImageInput {
  display: none;
}

.thumbnail {
  width: 179px;
  height: 153px;

  @media (max-width: 768px) {
    width: 100%;
    height: 150px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 135px;
  }

  & > div {
    &:nth-child(1) {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  height: 100%;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 100%;
    height: 201px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
  /* height: 120px;
  width: 146px; */
}

.avatarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  & > div {
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & video {
    height: 100%;
    aspect-ratio: 10 / 9;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  & .uploadDocument {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
}

.removeItem {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: #00000085;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: #fff;
    stroke: #fff;
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  /* height: 146px;
  width: 146px; */
  /* Colors */
  background-color: #ededed;

  @media (--viewportLarge) {
    /* background-color: var(--matterColorBright); */
  }

  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    @media (max-width: 600px) {
      width: 40px;
      height: 40px;
    }
  }
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.chooseImagedesktop {
  font-size: 12px;
  color: #595858;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
  letter-spacing: 0.12em;
  font-family: 'Montserrat';
}

.chooseImageBox {
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
}

.descriptionRoot {
  & > div {
    &:nth-of-type(1) {
      padding: 20px;
      border-radius: 4px;
      border-radius: 4px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #939393;
      box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);

      & textarea {
        padding: 0;
        border: none;
        height: 104px !important;
        overflow-y: auto !important;
      }
    }
  }
}