.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  visibility: hidden;
  font-size: 16px;
  text-align: center;
  width: 350px;
  position: absolute;
  bottom: 100%;
  background: var(--matterColorBright);
  padding: 5px 10px;
  opacity: 0;
  color: #000;
  z-index: 5;
  border-radius: 5px;
  transition: 100ms opacity;
  box-shadow: var(--boxShadowPopup);
  @media (max-width: 767px) {
    left: -200px;
    width: 320px;
  }
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
  color: #000;
}
