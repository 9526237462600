.navLink {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 56px !important;
    letter-spacing: -0.25px;
    color: #4A4A4A;
    
    @media (max-width : 768px){
        font-size: 16px;
        line-height: 32px !important;
    }
}