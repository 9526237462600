@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 32px;
    flex-direction: row;
  }
}

.successMessage {
  background-color: var(--attentionColorLight);
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.optionalMessageRoot {
  margin-top: 12px;

  & > div {
    &:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #939393;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  & textarea {
    height: 70px !important;
    overflow-y: auto !important;
    border: none;
    padding: 0;
  }
}

.addPhoto {
  width: 40%;
  background-color: var(--marketplaceColor);
  &:hover {
    background-color: var(--matterColorDark);
  }
}
.addAnotherDate {
  width: 100%;
  background-color: var(--marketplaceColor);
  &:hover {
    background-color: var(--matterColorDark);
  }
}

.location {
  /* width: calc(50% - 9px); */

  & > div {
    & > div {
      &:nth-child(2) {
        background: #ffffff;
        border: 1px solid #939393;
        border-radius: 4px;
        height: 45px;
        position: relative;

        & > div {
          border: none;
          border-radius: 4px;
          padding-left: 10px;
          top: 80px !important;
        }

        & input {
          border: none;
          height: 45px;
        }
      }
    }
  }
}

.field {
  /* margin-top: 15px; */
  width: 100%;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    width: calc(50% - 9px);
    margin-bottom: 0px;
  }

  & select {
    padding: 7px 37px 8px 17px;
    line-height: 20px;
    & option {
      font-size: 16px;
      line-height: 60px;
      height: 50px;
      display: block;
      min-height: 40px;
    }
  }
}

.signupSubHeading {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  padding-top: 20px;
  margin-top: 16px;
  margin-bottom: 26px;
  border-top: 1px solid #e0e0e0;
  text-align: center;
}

.selectRadioButtons {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 25px;

  @media (max-width: 560px) {
    flex-direction: column;
  }

  & .radioBox {
    /* margin-right: 39px; */

    & label {
      @media (max-width: 560px) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      & span {
        @media (max-width: 560px) {
          margin-left: 0;
        }
      }
    }
  }
}

.timeBox {
  & button {
    margin: 0 auto;
  }
  & > button {
    margin: 16px 0;
  }
}

.tableDate {
  text-align: left;
  width: 30%;
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.tableHeading {
  text-align: left;
  width: 23.3%;
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.tableData {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.removeExceptionButton {
  border: 1px solid #212121;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212121;
  cursor: pointer;

  & svg {
    & g {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 0;

  & button {
    border-radius: 100px;
  }
}

.nearVendorCheckbox {
  margin: 32px 0;

  &:after {
    content: '';
    position: absolute;
    background: #ded4d4;
    height: 1px;
    width: 80%;
    margin: auto;
    left: 0;
    right: 0;
  }

  & label {
    justify-content: center;
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 20px;
}

.quoteRequestLink {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4661ed;
  border: none;
  box-shadow: none;
  text-decoration: underline;

  &:hover {
    border: none;
    box-shadow: none;
    text-decoration: underline;
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.multiselectInput {
  margin-bottom: 36px;
}

.exactLocationText {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4661ed;
  margin-top: 15px;
  margin-bottom: 32px;
}

.workcheckBox {
  margin-top: 32px;
  margin-bottom: 21px;
}

.inputBox {
  & > div {
    & > div {
      & > div {
        border-bottom: 0;
        position: absolute;

        & svg {
          display: none;
        }
      }
    }
  }
}

.uploadBox {
  text-align: center;
  border: 1px solid #b1abab;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  & .uploadText {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #595858;

    @media (max-width: 768px) {
      display: none;
    }

    & span {
      font-size: x-large;
    }
  }
}

.imagesField {
  display: grid;
  margin-top: 20px;
  gap: 13px;
  grid-template-columns: repeat(3, 1fr);

  @media (--viewportSmall) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }

  & .uploadText {
    display: none;
  }
}

.fullField {
  display: grid;
  min-height: 200px;
  grid-template-columns: 100%;

  & > div {
    width: 100%;
  }
}

.thumbnail {
  /* margin: 24px 0 0 0; */
  @media (max-width: 768px) {
    background-color: #fff;
  }

  @media (--viewportLarge) {
    width: 104px;
    height: 104px;

    &:nth-child(even) {
      /* margin: 0 0 24px 0; */
    }
  }

  & > div {
    & > div {
      width: 104px;
      height: 104px;
    }
  }

  & button {
    top: 9px;
    right: 9px;
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    align-items: center;
    justify-content: center;

    & svg {
      top: 8px;
      left: 8px;

      & path {
        stroke: #000;
      }
    }
  }
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  margin-top: -30px;
  left: 0;
  background-color: #ffb169;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.priceInput {
  width: 94%;

  & input {
    padding: 12px;
    background-color: #fff;
  }

  @media (max-width: 620px) {
    width: 91%;
  }
}

.priceBoxWrap {
  /* display: grid;
  grid-template-columns: 170px 170px auto;
  gap: 40px;
  margin-top: 19px;

  @media(max-width: 620px) {
    grid-template-columns: 100%;
    gap: 25px;
  } */
}

.priceCheck {
  /* margin-top: 40px; */

  @media (max-width: 620px) {
    margin-top: 0;
  }

  & label {
    margin-top: 0;

    @media (max-width: 620px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 0 !important;
    }

    & span {
      margin-left: 10px;

      @media (max-width: 620px) {
        margin-left: 0;
      }
    }
  }
}

.accordanceBox {
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 10px;
  }
}

.accordanceBox a {
  text-align: center;
  display: block;
  margin-top: 2em;
  color: #333;
}

.accordanceBox a:hover {
  color: #666;
}

.accordion {
  /* border: solid 2px #f5f5f5; */
  transition: all 0.3s ease-in-out;
  margin-top: 0.25rem;

  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 0%), 0px 3px 3px rgb(0 0 0 / 0%), 0px 1px 8px rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.accordion:hover {
  /* background-color: #f5f5f5; */
}

.accordion[open] {
  /* background-color: #ebebeb;
  border-color: #ebebeb; */
}

.accordion[open] .accordionTitle {
  background-image: url('../../assets/uparrow.png');
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.accordion .priceBoxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.accordion .priceBoxWrap p {
  margin: 0;
}

.accordion .priceBoxWrap p + p {
  margin-top: 0.5em;
}

.accordion .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url('../../assets/downarrow.png');
  background-repeat: no-repeat;
  background-position: right 0.35rem top 0.625rem;
  background-size: 16px 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #212121;
  padding-right: 20px;
  display: flex;
  gap: 20px;

  @media (--) {
    background-position: right 0.75rem top 0.625rem;
  }
}

.accordion .accordionTitle::marker::-webkit-details-marker {
  display: none;
}

.dividerLine {
  margin: 24px 0;
  /* border: 1px solid #E0E0E0; */
}

.requestMessage {
  margin-top: 26px;

  & > div {
    margin-top: 15px;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  & > div {
    & > div {
      & span {
        top: 3px !important;
        right: 30px;
        cursor: pointer;
      }

      &:before {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background: #4661ed;
        top: 22px;
        border-radius: 50%;
      }
    }

    & input {
      border: none;
      border-bottom: 1px solid #00000061;
      border-radius: 0;
      margin-left: 26px;
      padding: 5px 35px 5px 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #939393;
      width: 80%;

      @media (--viewportMedium) {
        width: 80%;
      }
    }
  }
}

.deliveryCheck {
  margin-top: 28px;

  & label {
    justify-content: flex-end;

    & span {
      @media (max-width: 620px) {
        margin-left: 10px;
      }
    }
  }
}

.subTitle {
  text-align: center;
  font-size: 20px;
}

.Title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 22px;
  margin-top: 0;
}
