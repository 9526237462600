@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.removelabel {
  margin-left: 10px;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 26px;
  }
}

.vendorPage {
  & input {
    padding: 12px 21px;
  }

  & select {
    padding: 12px 21px;
  }
}

.firstNameRoot {
  width: 100%;
  margin-bottom: 18px;
}

.textInputRow {
  width: calc(61% - 6px);

  @media (max-width: 600px) {
    width: 100%;
  }

  & input {
    padding: 12px 21px 12px 35px;
  }
}

.inputBox {
  margin-top: 26px;
}

.locationAddress {
  & div {
    &:nth-of-type(1) {
      opacity: 0;
      width: 0;
    }
  }
}

.countryBox {
  width: calc(39% - 6px);

  @media (max-width: 600px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 60px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  width: 176px;
  height: 176px;
}

.thumbnail {
  /* margin: 24px 0 0 0; */
  width: 100%;

  @media (--viewportLarge) {
    /* width: calc(50% - 12px);
    margin: 0 24px 24px 0; */

    &:nth-child(even) {
      /* margin: 0 0 24px 0; */
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  /* margin: 24px 0 0 0; */
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    /* width: calc(50% - 12px);
    margin: 0 0 24px 0; */
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  height: 176px;
  width: 176px;

  @media (max-width: 460px) {
    height: 156px;
    width: 156px;
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: #ededed;

  @media (--viewportLarge) {
    /* background-color: var(--matterColorBright); */
  }

  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.workRemote {
  margin-top: 17px;

  @media (max-width: 460px) {
    width: 100%;
    margin-top: 37px;
  }
}

.chooseImageBox {
  text-align: center;

  & .chooseImagedesktop {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #000000;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  font-size: 10px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.topBoxWrapper {
  display: flex;
  gap: 38px;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.avatarContainer {
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 100%;
  height: 100%;
  & div {
    height: 100%;
  }
  & img {
    object-fit: contain;
    max-height: 273px;
  }
  & .avatar {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-image: unset;
    & img {
      border-radius: 4px;
    }
  }
}

.namesBox {
  width: 100%;
}

.workcheckBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 17px;

  & label {
    margin-bottom: 26px;

    @media (max-width: 500px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
    }

    & span {
      &:nth-of-type(2) {
        @media (max-width: 500px) {
          margin-left: 0;
        }
      }
    }
  }
}

.workRadiobutton {
  & label {
    & span {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.12em;
      color: #595858;
    }
  }
}

.anotherAddressLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 30px;
}

.error {
  margin-top: 10px;
  color: var(--failColor);
}
