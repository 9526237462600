.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover+label .box,
  &:focus+label .box,
  &:checked+label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover+label .boxSuccess,
  &:focus+label .boxSuccess,
  &:checked+label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked+label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked+label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus+label .text,
  &:hover+label .text,
  &:checked+label .text {
    color: #000;
  }
}

.label {
  display: none;
  align-items: center;
  padding: 0;

  @media (max-width: 767px) {
    margin-top: -10px;
  }
}

.checkboxWrapper {
  /* This should follow line-height */
  display: none !important;
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}

.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorNegative);
}

.text {}

.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  color: #595858;
  margin-left: 11px;
}

input[type='checkbox']+label {
  display: flex;
  cursor: pointer;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']+label:before {
  content: "";
  background-color: #fff;
  /* background-image: url(../../assets/auth-images/check.png); */
  /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.84753 6.64574L10.1973 0.295964C10.3946 0.0986543 10.6457 0 10.9507 0C11.2556 0 11.5067 0.0986543 11.704 0.295964C11.9013 0.493273 12 0.744394 12 1.04933C12 1.35426 11.9013 1.60538 11.704 1.80269L4.6009 8.90583C4.38565 9.12108 4.13453 9.2287 3.84753 9.2287C3.56054 9.2287 3.30942 9.12108 3.09417 8.90583L0.295964 6.10762C0.0986543 5.91031 0 5.65919 0 5.35426C0 5.04933 0.0986543 4.79821 0.295964 4.6009C0.493273 4.40359 0.744394 4.30493 1.04933 4.30493C1.35426 4.30493 1.60538 4.40359 1.80269 4.6009L3.84753 6.64574Z' fill='white'/%3E%3C/svg%3E%0A"); */
  background-position: center;
  border: 0.1em solid var(--matterColorNegative);
  border-radius: 5px;
  display: inline-block;
  width: 26px;
  min-width: 26px;
  height: 26px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;

  @media (max-width: 767px) {
    min-width: 26px;
  }
}

input[type='checkbox']+label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked+label:before {
  background: var(--successColor);
  border-color: var(--matterColorNegative);
  color: white;
  background-image: url(../../assets/auth-images/checked.png);
  background-repeat: no-repeat;
  /* background-size: 12px; */
  background-position: center;
}

input[type='checkbox']:disabled+label:before {
  transform: scale(1);
  border-color: var(--matterColorNegative);
}

input[type='checkbox']:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f;
}