@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  /* background-color: var(--matterColorBright); */
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  &>*:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: #fff;

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}

.searchFiltersPanelOpen {
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.breadcrumbsBox {
  &>div {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
  }
}

.mapToogleButton {
  display: flex;
  gap: 20px;
  align-items: center;

  @media(max-width: 768px) {
    display: none;
  }

  & .mapLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #595858;
  }
}

.formInput {
  position: relative;
  margin-top: 33px;

  & input {
    height: 51px;
    padding: 14px 14px 14px 57px;
  }

  & span {
    position: absolute;
    top: 8px;
    left: 17px;
  }
}

.navLinksBox {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 16px;
  margin-top: 29px;

  & nav {
    &>div {
      &:nth-child(7) {
        display: none;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(5) {
        display: none;
      }
    }

    /* background-color: red; */
    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.searchTabs {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 16px;
  margin-top: 29px;
  display: flex;

  @media(max-width: 768px) {
    flex-wrap: wrap;
  }

  & nav {
    @media(max-width: 768px) {
      flex-wrap: wrap;
    }

    &>div {
      @media(max-width: 768px) {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.box {
  display: flex;
  gap: 21px;
  align-items: center;

  @media(max-width: 768px) {
    align-items: flex-end;
  }

  & .filterList {
    display: flex;
    gap: 21px;
    align-items: center;

    @media(max-width: 768px) {
      flex-wrap: wrap;
    }

    &>div {
      &:nth-child(3) {
        order: 1;
        display: flex;

        @media(max-width: 768px) {
          width: 100%;
        }
      }

      &:nth-child(1) {
        order: 4;
        display: flex;

        @media(max-width: 1300px) {
          display: none;
        }
      }

      &:nth-child(4) {
        order: 2;
        display: flex;

        @media(max-width: 768px) {
          width: 100%;
        }

        & button {
          @media(max-width: 768px) {
            width: 100%;
          }
        }
      }

      &:nth-child(2) {
        order: 3;
        display: flex;

        @media(max-width: 1300px) {
          display: none;
        }
      }
    }
  }

  & .filterLess {
    display: flex;
    gap: 21px;
    align-items: center;

    &>div {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        order: 1;
        display: flex;
      }

      &:nth-child(4) {
        order: 2;
        display: flex;
      }
    }
  }
}

.filterBox {
  cursor: pointer;
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #FFFFFF;

  @media(max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #000000;
  background: rgb(0 0 0);

  @media(max-width: 768px) {
    /* height: 40px; */
  }
}