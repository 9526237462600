@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 11px 24px 0 24px; */
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}
.heading {
  text-align: center;
  & h2{
    font-size: 28px;
    margin-bottom: 8px;
  }
  & p{
    margin-top: 0px;
  }
}
.saveLater {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #212121;
}

.locationTimeModal {
  &>div {
    &>div {
      padding: 0 !important;
    }
  }
}