@import '../../styles/customMediaQueries.css';

.root {}

.eventCardBox {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
}

.mainWrapperListBox {
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  & .tableViewBox {
    border-top: 1px solid #939393;
    padding-top: 34px;
    @media (max-width: 767px) {
      max-width: 100vw;
      overflow-x: scroll;
    }
    & table {
      width: 100%;

      & th {
        background: #f8f8f8;
        box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        padding: 27px 17px;
        text-align: left;

        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 17%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 18%;
        }

        & .headingName {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #333333;
        }
      }

      & tbody {
        & tr {
          background: #ffffff;
          box-shadow: inset 0px -1px 0px #e5e5e5;

          &:hover {
            background: #fffcf8;
          }
        }

        & td {
          padding: 9px 17px;

          & .tableData {
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            letter-spacing: 0.05em;
            color: #212121;
          }

          & .tableDate {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.05em;
            color: #595858;
          }

          & .tableButton {
            & button {
              min-height: 24px;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #ffffff;
              text-align: center;
              border-radius: 4px;
            }
          }
          & .tableYellowButton {
            & button {
              color: #151414;
              background-color: #f0eb6f;
            }
          }
        }
      }
    }
  }

  & .cardViewBox {
    border-top: 1px solid #939393;
    padding-top: 96px;

    & .eventCardsList {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: repeat(3, 1fr); */
      /* column-gap: 62px; */
      row-gap: 114px;

      @media (max-width: 1440px) {
        column-gap: 30px;
      }

      @media (max-width: 1024px) {
        column-gap: 20px;
      }

      @media (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 620px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.quotesCard {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  & .quotesTop {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;

    & .favourited {
      background: #ffb169 !important;

      & svg {
        & path {
          fill: #fff;
        }
      }
    }

    & .favouriteBadge {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 20px 20px;
      padding: 21px 9px 16px;
      cursor: pointer;

      & svg {
        cursor: pointer;
      }
    }

    & .quotesLogoBox {
      margin-top: -60px;

      & img {
        width: 123px;
        height: 123px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      }
    }

    & .dropdownDots {
      display: flex;
      margin-top: 26px;
      cursor: pointer;

      & svg {
        & path {
          fill: #595858;
        }
      }
    }

    & .dropdownList {
      background: #ffffff;
      position: absolute;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 10px 0;
      z-index: 5;
      & ul {
        margin: 0;

        & li {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 36px;
          letter-spacing: 0.05em;
          color: #595858;
          padding: 0 8px;
          cursor: pointer;

          &:hover {
            background-color: #e3e3e3;
          }

          & button {
            margin-right: 9px;
            color: #000;
          }

          &:nth-child(1) {
            & span {
              & svg {
                height: 16px;
                width: 16px;

                & path {
                  fill: #595858;
                }
              }
            }
          }

          &:nth-child(2) {
            & span {
              & svg {
                height: 16px;
                width: 16px;

                & path {
                  /* stroke: #595858; */
                }
              }
            }
          }

          &:nth-child(3) {
            & span {
              & svg {
                height: 16px;
                width: 16px;

                & path {
                  fill: none;
                }
              }
            }
          }
        }
      }
    }
  }

  & .quotesBody {
    text-align: center;
    margin-top: 18px;
    padding: 0 22px 22px;

    & h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 38px;
      letter-spacing: 0.09em;
      color: #1b1a1a;
      transform: matrix(1, 0, 0, 1, 0, 0);
      margin: 0;
    }

    & .quotesData {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 38px;
      letter-spacing: 0.09em;
      color: #1b1a1a;
      transform: matrix(1, 0, 0, 1, 0, 0);

      & svg {
        & path {
          fill: #1b1a1a;
        }
      }
    }

    & .dividerLine {
      background-color: #939393;
      height: 1px;
      margin-top: 19px;
      margin-bottom: 23px;
    }

    & .quotesTotal {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: #595858;
    }

    & .quotesAmount {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: #4661ed;
    }
  }

  & .bottomButton {
    & .buttonLink {
      border-radius: 4px;
      background: #212121;
      border-radius: 4px;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
      }
    }

    & .urgentButton {
      background: red;
    }
  }
  & .bottomYellowButton {
    & .buttonLink {
      color: #151414;
      background-color: #f0eb6f;
    }
  }
}

.messageBox {
  padding: 0px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 576px;
  @media (max-width: 767px) {
    height: auto;
  }
  & .messageScroll {
    height: auto;
    overflow-y: auto;
    margin-bottom: 40px;
    padding-right: 15px;
    flex: 1;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .leftMessage {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 0px;
    justify-content: space-between;
    border-bottom: 1px solid #e0dfdf;
    padding: 16px 0;
    position: relative;
    cursor: pointer;
    &.leftOwnMessage {
      justify-content: flex-start;
    }
    &.messsageTabList {
      border: 0;
    }
    & .quotesBody {
      & h3 {
        margin: 0;
      }
      & * {
        font-size: 14px;
        font-style: normal;
        line-height: 19px;
      }
    }
    @media (max-width: 767px) {
      gap: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    & .messagesRightOptionsDesktop {
      text-align: right;
      margin-top: -6px;
      @media (max-width: 767px) {
        text-align: left;
        margin-left: 81px;
      }
      & .menuIconQuote {
        @media (max-width: 767px) {
          position: absolute;
          top: 14px;
          right: -6px;
        }
      }
      & p {
        margin: 0;
      }
      & .status {
        color: #4661ed;
        text-align: right;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.087px;
        &.requested {
          color: #4661ed;
        }
        &.pending {
          color: #eac710;
        }
        &.accepted {
          color: #46d722;
        }
        &.declined {
          color: #ae4040;
        }
        &.expired {
          color: #a7a7a7;
        }
        &.info {
          color: #eac710;
        }
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    & .eventTitle {
      color: #000;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
      letter-spacing: -0.087px;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    & .messagesLeftOptions {
      display: flex;
      align-items: center;
      gap: 18px;

      justify-content: space-between;
    }
    & .chatProfileImage {
      width: 63px;
      height: 63px;
      min-width: 63px;
      overflow: hidden;
      border: 3px solid #ffffff;
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
      border-radius: 100px;
      & svg {
        padding-top: 0;
        height: 63px;
        width: 30px;
      }
      & div {
        height: auto !important;
      }
      & img {
        width: 63px;
        height: 63px;
        border-radius: 100px;
        overflow: hidden;
      }
    }

    & .chatDisplayData {
      & .nameDate {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Lora;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.087px;
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 20px;
        }
        & span {
          color: #595858;
        }
      }
      & .timeText {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 300% */
        letter-spacing: -0.087px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          line-height: 14px;
        }
      }
      & .messageDetail {
        color: #313131;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.087px;
        max-width: 70%;
        @media (max-width: 767px) {
          font-size: 10px;
        }
        & .boldText {
          font-weight: 600;
        }
      }
    }
  }

  & .rightMessage {
    display: flex;
    align-items: flex-end;
    gap: 18px;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    & .chatProfileImage {
      width: 52px;
      height: 52px;
      overflow: hidden;
      border: 3px solid #ffffff;
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
      border-radius: 100px;
      & svg {
        padding-top: 0;
        height: 52px;
        width: 30px;
      }
      & div {
        height: auto !important;
      }
      & img {
        width: 52px;
        height: 52px;

        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      background: #e3e8ff;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
      border-radius: 4px;
      padding: 18px 33px;
      max-width: 70%;

      & .nameDate {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 7px;

        & span {
          color: #595858;
        }
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
    }
  }
}

.dropdownDots {
  cursor: pointer;

  & path {
    fill: #000;
  }
}

.menuIconQuote {
  position: relative;
}

.dropdownList {
  min-width: 200px;
  position: absolute;
  right: 0;
  background: #fff;
  top: 100%;
  z-index: 100;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  & button {
    width: 100%;
    text-align: left;
    padding: 0 10px;
    & svg {
      margin-right: 10px;
      width: 20px;
    }
  }
}

.days {
  margin: 0 0 4px;
  font-size: 12px !important;
}
