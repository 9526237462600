@import '../../styles/customMediaQueries.css';

.root {}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }

  &>div {
    &:nth-child(2) {
      background: #FFFFFF;
      border: 1px solid #939393;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 20px;

      & textarea {
        height: 104px !important;
        overflow-y: auto !important;
        border: none;
        padding: 0;
      }
    }
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}