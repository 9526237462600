.root {
  & label {
    align-items: flex-start;
    & span:nth-child(2) {
      margin-top: 4px;
    }
  }
}

.eventDetailsForm {
  & h2 {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-top: 28px;
    margin-bottom: 22px;
    color: #595858;
  }

  & .checkboxWrapper {
    & .eventCheckBox {
      & label {
        margin-bottom: 16px !important;
      }
    }
  }

  & .detailRadio {
    display: flex;
    margin: 37px 0;
    flex-wrap: wrap;

    & .radioBox {
      margin-right: 21px;
    }
  }

  & .quoteBuilder {
    & h4 {
      margin: 0 0 20px 0;
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #212121;
    }

    & .quoteInputs {
      border-top: 1px solid #939393;
      padding-top: 36px;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 37px;
      @media (max-width: 1300px) {
        flex-wrap: wrap;
      }
      @media (max-width: 920px) {
        flex-wrap: wrap;
      }

      & .uploadBoxLeft {
        max-width: 400px;

        @media (max-width: 920px) {
          width: 100%;
        }

        & .documentUpload {
          margin-top: 0px;

          & h4 {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.05em;
            color: #595858;
          }
        }
      }

      & .optionalNote {
        flex: 1;

        @media (max-width: 1300px) {
          width: 100%;
          flex: 100%;
        }

        & .description {
          & > div {
            &:nth-child(2) {
              background: #ffffff;
              border: 1px solid #939393;
              box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              box-sizing: border-box;
              padding: 20px;

              & textarea {
                height: 310px !important;
                overflow-y: auto !important;
                border: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  & .addQuoteOption {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 22px;
    align-items: center;
  }

  & .additionalInformation {
    & h4 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #212121;
      margin-top: 0;
      margin-bottom: 20px;
    }

    & .informationInput {
      border-top: 1px solid #939393;
      padding-top: 40px;

      & .informationTextarea {
        & > div {
          &:nth-child(2) {
            background: #ffffff;
            border: 1px solid #939393;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 20px;

            & textarea {
              height: 96px !important;
              overflow-y: auto !important;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }

    & .introductionInput {
      & .introductionTextarea {
        & > div {
          &:nth-child(2) {
            background: #ffffff;
            border: 1px solid #939393;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 20px;

            & textarea {
              height: 153px !important;
              overflow-y: auto !important;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.link {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #4661ed;
  cursor: pointer;

  & span {
    margin-right: 13px;
  }
}

.quoteCatInputs {
  display: flex;
  /* padding-top: 36px; */
  margin-bottom: 37px;
  justify-content: space-between;
  /* border-top: 1px solid #939393; */

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
}

.professionalBioRoot {
  margin-bottom: 37px;

  & > div {
    &:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #939393;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  & textarea {
    height: 104px !important;
    overflow-y: auto !important;
    padding: 0;
    border: none;
  }
}

.defaultLink {
  margin-top: 18px;
  margin-bottom: 27px;
  text-align: right;
}

.bottomButton {
  & button {
    width: 366px;
    margin: auto;

    @media (max-width: 576px) {
      width: auto;
      padding: 4px 20px;
    }
  }
}

.imageUploadWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 16px;
  margin-bottom: 30px;
  max-width: 400px;
  @media (max-width: 600px) {
    gap: 15px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.thumbnail {
  width: 122px;
  height: 105px;

  @media (max-width: 768px) {
    width: 100%;
    height: 150px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 135px;
  }

  & > div {
    &:nth-child(1) {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

.avatarContainer {
  position: relative;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
  }

  & video {
    height: 100%;
    aspect-ratio: 10 / 9;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }

  & .uploadDocument {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  & .removeItem {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background-color: #00000085;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin-top: 0;
  /* height: 146px;
  width: 146px; */
  /* Colors */
  background-color: #ededed;
  padding: 10px;
  @media (--viewportLarge) {
    /* background-color: var(--matterColorBright); */
  }

  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 5px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    width: 40px;
    height: 40px;
    @media (max-width: 600px) {
      width: 40px;
      height: 40px;
    }
  }
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.chooseImagedesktop {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.12em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.chooseImageBox {
  margin-top: 6px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
}

.bottomButton {
  width: 366px;
  margin-top: 40px;
  margin: 40px auto 0;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    border-radius: 100px;
    border: 0px;
    font-family: Lora;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.04px;
    letter-spacing: 0.12em;
    text-align: center;
    background-color: #000;
    padding: 16px 40px;
    color: #fff;
  }
}

.subCategoryContainer {
  border-top: 1px solid #939393;
  padding: 13px 0 0 0;
}

.requirementBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    gap: 20px;
  }

  & .requirementSection {
    margin-right: 35px;

    & .icon {
      margin-right: 10px;
    }

    & span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #212121;
    }

    & .plannedLink {
      color: #4661ed;
      font-weight: 500;
      margin-left: 5px;
      text-decoration: underline;
    }

    & .boldText {
      font-weight: 500;
      margin-left: 5px;
      color: #595858;

      & span {
        font-weight: 500;
        color: #595858;
      }
    }
  }
}

.acceptPaymentSubtitle {
  margin: 0 0 10px 0;
}

.requirementContainer {
  padding: 22px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 21px;
  & .requirementBox {
    border-bottom: 1px solid #939393;
    padding-bottom: 12px;
  }
}

.filePreview {
  overflow: hidden;
  height: 105px;
  background: #ededed;
  position: relative;
  & img {
    object-fit: contain;
    height: 105px;
  }
  & .removeItem {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background-color: #00000085;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.pdfPreview {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
