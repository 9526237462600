.root {
  position: relative;
  display: inline-block;
}

.label {
  letter-spacing: 0.2px;
  width: 247px;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  height: 51px;
  min-height: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 0.5px solid #939393;
  border-radius: 4px;
  cursor: pointer;
  
  /* &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  } */
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}