@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media(max-width: 500px) {
    flex-wrap: wrap;
    gap: 26px;
  }
}

.emailInput {
  margin: 26px 0;
}

.firstNameRoot {
  width: calc(50% - 9px);

  @media(max-width: 500px) {
    width: 100%;
  }

  & input {
    padding: 12px 21px;
  }
}

.passwordWrapper {
  width: calc(50% - 9px);

  @media(max-width: 500px) {
    width: 100%;
  }

  & input {
    padding: 12px 21px;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 47px;

  & label {
    & span {
      &:nth-of-type(2) {
        /* font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.12em;
        color: #000000; */
      }
    }
  }

  & .submitButton {
    margin-top: 57px;

    @media(max-width: 500px) {
      margin-top: 42px;
    }
  }
}

.singupcheck {
  & label {
    margin-bottom: 26px !important;

    @media(max-width: 500px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
    }

    & span {
      &:nth-of-type(2) {
        margin-left: 11px;
        text-align: left;

        @media(max-width: 500px) {
          margin-left: 0;
        }
      }
    }
  }
}

.singupcheckLink {
  & label {
    margin-bottom: 26px;

    @media(max-width: 768px) {
      margin-bottom: 26px !important;
    }

    @media(max-width: 500px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
    }

    & span {
      &:nth-of-type(2) {
        margin-left: 11px;
        text-align: left;

        @media(max-width: 500px) {
          margin-left: 0;
        }
      }
    }
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #4661ED;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.desktopText {
  display: block !important;
}

.signupHeadingMobile {
  display: none;

  @media(max-width: 768px) {
    display: block !important;
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #000000;
    margin: 0;
    font-family: 'Montserrat';
  }

  @media(max-width: 460px) {
    font-size: 22px;
  }

  & span {
    display: block;
  }
}

.signupFirstWrapper {
  & .signupHeading {
    font-weight: 300;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #000000;
    margin: 0;
    font-family: 'Montserrat';

    @media(max-width: 768px) {
      display: none !important;
    }

    & span {
      display: block;
    }

    @media(max-width: 768px) {
      line-height: 33px;
    }
  }

  & .signupSubHeading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #595858;
    margin-bottom: 0;
    margin-top: 25px;

    @media(max-width: 768px) {
      margin-top: 16px;
    }
  }

  & .selectRadioButtons {
    display: flex;
    align-items: center;
    margin-top: 26px;
    flex-wrap: wrap;

    @media(max-width: 768px) {
      /* row-gap: 22px; */
    }

    & .radioBox {
      margin-right: 25px;

      @media(max-width: 768px) {
        margin-right: 34px;
      }

      & label {
        @media(max-width: 500px) {
          margin: 0;
        }

        & span {
          &:nth-of-type(1) {
            margin-right: 9px;
          }

          &:nth-of-type(2) {
            /* font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.12em;
            color: #595858; */
          }
        }
      }

      & input {
        height: 26px;
        width: 26px;
      }
    }
  }

  & .dividerLine {
    margin-top: 38px;
    margin-bottom: 26px;
    border: 1px solid #E0E0E0;

    @media(max-width: 768px) {
      margin-top: 33px;
      margin-bottom: 37px;
    }
  }
}

.passwordWrapper {
  position: relative;
}

.passwordShow {
  position: absolute;
  cursor: pointer;
  top: 45px;
  right: 15px;
  z-index: 10;
  text-decoration: underline;
  color: var(--marketplaceColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.authLinksSignup {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    margin-top: 24px;
  }

  & a {
    font-weight: 700;
    color: #4661ED;
    margin-left: 5px;
    text-decoration: underline;
  }
}