@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 0;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 46px;

  @media(max-width:460px) {
    margin-bottom: 40px;
    margin-top: 0;
    text-align: center;
    padding-bottom: 0;
  }

  & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.12em;
    color: #000000;

    & a {
      & span {
        color: var(--marketplaceColorLight);
      }
    }
  }
}

.modalHelperLink {
  text-decoration: underline;
  text-decoration-color: #4661ED !important;

  @media(max-width: 460px) {
    display: block;
  }

  & span {
    color: #4661ED;
  }
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.passwordWrapper {
  position: relative;
}

.passwordInputBox {
  margin-bottom: 15px;
}

.passwordShow {
  position: absolute;
  cursor: pointer;
  top: 45px;
  right: 15px;
  z-index: 10;
  text-decoration: underline;
  color: var(--marketplaceColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.loginHeading {
  margin-top: 0;
  margin-bottom: 26px;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 30px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;

  @media(max-width: 460px) {
    font-size: 22px;
  }
}

.inputBox {
  margin-bottom: 26px;

  @media(max-width:460px) {
    margin-bottom: 22px;
  }

  & input {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #939393;
    border-radius: 4px;
    padding: 12px 21px;
  }

  & label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #595858;
    padding: 0;
    margin-bottom: 14px;
  }
}

.memberFreeText {
  display: block;
}

.authLinksLogin {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    margin-top: 24px;
  }

  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  & a {
    font-weight: 700;
    color: #4661ED;
    margin-left: 5px;
    text-decoration: underline;
  }
}