.root {}

.chatDetailsForm {
  & .chatLeft {
    position: relative;

    & .messageBox {
      &>div {
        background-color: red;

        &:nth-child(1) {
          box-sizing: border-box;
          padding: 20px 255px 20px 20px;
          background: #FBFBFF;
          border: 1px solid #E0E0E0;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;

          @media(max-width: 600px) {
            padding: 10px;
          }

          @media(max-width: 600px) {
            box-shadow: none;
          }

          & textarea {
            height: 50px !important;
            overflow-y: auto !important;
            border: none;
            padding: 0;
          }
        }
      }
    }

    & .buttonBrowse {
      display: flex;
      position: absolute;
      right: 0;
      transform: translate(-22px, -133%);
      align-items: center;

      @media(max-width: 600px) {
        position: relative;
        transform: unset;
        margin-top: 10px;
      }

      & button {
        width: 163px;
      }

      & .uploadicon {
        cursor: pointer;
        background: #212121;
        border-radius: 8px;
        padding: 10px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-bottom: 0 !important;
      }
    }
  }
}

.borderButton {
  cursor: pointer;
}