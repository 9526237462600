@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  padding: 3px;
  /* Static map: dimensions are 640px */
  height: 300px;
  width: 100%;
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  border: 0;
  cursor: pointer;
  position: relative;

  @media (max-width: 460px) {
    width: 100%;
    height: 272px;
  }

  &>div {
    &>div {
      &>div {
        &:nth-child(3) {
          right: 48px;
          position: absolute;
          bottom: 80px;
        }
      }
    }
  }
}

.mapTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  margin-top: 0;
  margin-bottom: 23px;
}

.rangeSliderBox {
  margin-top: 34px;
}

.dividerLine {
  margin: 34px 0;
  border: 1px solid #E0E0E0;
}

.rangeSliderInput {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  border: none;
}

.rangeSliderInput[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #ac51b5;
  border-radius: 25px;
  border: 0px solid #000101;
}


.rangeSliderInput[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

.rangeSliderInput[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 28px;
  width: 72px;
  border-radius: 50px;
  background: #000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}

.rangeSliderInput[type=range]:focus::-webkit-slider-runnable-track {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

.rangeSliderInput[type=range]::-moz-range-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #ac51b5;
  border-radius: 25px;
  border: 0px solid #000101;
}

.rangeSliderInput[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 39px;
  border-radius: 7px;
  background: red;
  cursor: pointer;
}

.rangeSliderInput[type=range]::-ms-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}

.rangeSliderInput[type=range]::-ms-fill-lower {
  background: #ac51b5;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.rangeSliderInput[type=range]::-ms-fill-upper {
  background: #ac51b5;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.rangeSliderInput[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 39px;
  border-radius: 7px;
  background: red;
  cursor: pointer;
}

.rangeSliderInput[type=range]:focus::-ms-fill-lower {
  background: #ac51b5;
}

.rangeSliderInput[type=range]:focus::-ms-fill-upper {
  background: #ac51b5;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editPlanIcon {
  margin-left: 5px;
}

.weekDay {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #595858;
  border: 1px solid #D8DAD9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.weeksSection {
  margin-bottom: 28px;
}

.entries {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.availabilityPlanBox {
  height: calc(100vh - 100px);
  box-sizing: border-box;
  overflow-y: auto;
}