@import '../../styles/customMediaQueries.css';

.leftSidebar {
  background: #ffffff;
  border-radius: 4px;
  width: 37%;
  padding: 24px 0;

  @media (max-width: 920px) {
    width: 100%;
  }

  & h3 {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #212121;
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 0 16px;
  }

  & .chatList {
    padding: 0 0px 28px 0;
    height: 575px;
    overflow: auto;
    margin-top: 30px;

    @media (max-width: 920px) {
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .chatUsers {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #e0e0e0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    cursor: pointer;
    padding: 20px 10px;
    border-radius: 0;

    &:nth-child(1) {
      /* padding-top: 0; */
    }

    &:hover {
      background-color: #ffb16930;
    }

    & .userImage {
      position: relative;

      & img {
        width: 56px;
        height: 56px;
        border: 3px solid #ffffff;
        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
        border-radius: 100px;
      }

      & .unreadCount {
        position: absolute;
        background: #4661ed;
        border: 1px solid #ffffff;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: -7px;
        bottom: 1px;
      }
    }

    & .chatData {
      & .userName {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #212121;
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #212121;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}

.selectedChatUser {
  background-color: #ffb169d1;
}

.coverImage {
  position: relative;
  & img {
    width: 100%;
    height: 193px;
    object-fit: cover;
    border-radius: 0;
    @media (max-width: 767px) {
      border-radius: 8px 8px 0 0;
    }
  }

  & .chatIcon {
    & svg {
      transform: rotate(90deg);
    }
  }
}

.chatPageModal {
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media (max-width: 767px) {
    top: 112px;
    margin-top: 100px;
  }
  & > div {
    & > div {
      padding: 98px 85px 23px !important;
      position: relative;
      border-radius: 8px !important;
      @media (max-width: 767px) {
        padding: 98px 20px 23px !important;
      }
    }
  }
  & .chatImage {
    position: absolute;
    top: -69px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    width: 138px;
    height: 138px;
    border-radius: 50%;
    & img {
      width: 138px;
      height: 138px;
    }
  }
  & h2 {
    color: #000;
    text-align: center;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 222.222% */
    letter-spacing: 3.6px;
    margin: 0;
  }
  & h3 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 153.846% */
    letter-spacing: 3.12px;
    margin: 0 0 29px;
  }
  & button {
    margin-bottom: 15px;
  }
}
.chatMedia {
  width: 400px;
  height: auto;
  max-width: 100%;
}

.rightSideFullHeading {
  width: 100%;

  @media (max-width: 920px) {
    /* display: none; */
  }

  & .rightheadingBox {
    align-items: center;
    background: #0f0f0e;
    border-radius: 4px;
    display: flex;
    width: 100%;
    padding: 13px 26px;
    justify-content: space-between;

    & .detailUser {
      display: flex;
      align-items: center;
      gap: 26px;

      @media (max-width: 500px) {
        gap: 10px;
      }

      & .backPage {
        display: none;

        @media (max-width: 920px) {
          display: block;
        }
      }

      & .chatProfileImage {
        width: 68px;
        height: 68px;
        overflow: hidden;
        & img {
          width: 68px;
          height: 68px;
          overflow: hidden;
          border: 3px solid #ffffff;
          filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
          border-radius: 100px;
          object-fit: cover;

          @media (max-width: 500px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      & .chatDisplayData {
        & .userName {
          font-family: 'Lora';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.15px;
          color: #ffffff;
        }

        & .messageDetail {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #ffffff;
        }
      }
    }

    & .rightTopLink {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;

      & a {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 38px;
        text-decoration-line: underline;
        color: #ffffff;
        margin-right: 28px;
      }

      & .yellowButton {
        background-color: #f0eb6f;
        font-family: 'Lora';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.12em;
        color: #212121;
        min-height: 43px;
        padding: 0 20px;

        & svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.rightMessageBox {
  width: 70%;

  @media (max-width: 920px) {
    /* display: none; */
  }

  & .rightheadingBox {
    align-items: center;
    background: #0f0f0e;
    border-radius: 4px;
    display: flex;
    width: 100%;
    padding: 13px 26px;
    justify-content: space-between;

    & .detailUser {
      display: flex;
      align-items: center;
      gap: 26px;

      & .chatProfileImage {
        width: 68px;
        height: 68px;
        overflow: hidden;
        & img {
          width: 68px;
          height: 68px;
          border: 3px solid #ffffff;
          filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
          border-radius: 100px;
          object-fit: cover;
        }
      }

      & .chatDisplayData {
        & .userName {
          font-family: 'Lora';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.15px;
          color: #ffffff;
        }

        & .messageDetail {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #ffffff;
        }
      }
    }

    & .rightTopLink {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;

      & a {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 38px;
        text-decoration-line: underline;
        color: #ffffff;
        margin-right: 28px;
      }

      & .yellowButton {
        background-color: #f0eb6f;
        font-family: 'Lora';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.12em;
        color: #212121;
        min-height: 43px;
        padding: 0 20px;

        & svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.MessageLists {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 9px;
  padding: 20px;
  @media (max-width: 767px) {
    padding: 0;
    max-width: calc(100vw - 40px);
  }
  & .chatDateUpdate {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #595858;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: #ededed;
      left: 0;
      height: 1px;
      width: 35%;
      top: 7px;
    }

    &:after {
      content: '';
      position: absolute;
      background: #ededed;
      right: 0;
      height: 1px;
      width: 35%;
      top: 7px;
    }
  }
}

.messageBox {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 720px;
  /* &.messageBox2 {
    height: auto;
  } */
  & .messageScroll {
    height: calc(65vh - 50px);
    overflow: auto;
    margin-bottom: 30px;
    padding-right: 15px;
    padding-left: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .leftMessage {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 20px;
    & .QuoteWrapper {
      flex: 1;
      max-width: 380px;
    }
    & .chatProfileImage {
      width: 52px;
      height: 52px;
      overflow: hidden;
      & img {
        width: 52px;
        height: 52px;
        border: 3px solid #ffffff;
        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      & .nameDate {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 7px;

        & span {
          color: #595858;
        }
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
        max-width: 70%;
      }
    }
  }

  & .rightMessage {
    display: flex;
    align-items: flex-end;
    gap: 18px;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    & .QuoteWrapper {
      flex: 1;
      max-width: 380px;
    }
    & .chatProfileImage {
      width: 52px;
      height: 52px;
      overflow: hidden;
      & img {
        width: 52px;
        height: 52px;
        border: 3px solid #ffffff;
        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      background: #e3e8ff;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
      border-radius: 4px;
      padding: 18px 33px;
      max-width: 70%;

      & .nameDate {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 7px;

        & span {
          color: #595858;
        }
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
    }
  }
}

.noEventCategoryImg {
  margin: 0px auto;
  max-width: 1350px;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  padding: 0px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid #939393;
  & h4 {
    color: #212121;
    font-family: Lora;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    margin: 0;
  }
  & p {
    color: #4661ed;
    text-align: center;
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.087px;
    margin: 0;
    text-align: right;
    @media (max-width: 767px) {
      max-width: 50%;
    }
  }
}

.noMessageText {
  text-align: center;
  margin-top: 80px;
}

.timeWrapper {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 28px;
}

.emptyLine {
  flex: 1;
  border-bottom: 1px solid #ededed;
}

.QuoteWrapper {
  margin-bottom: 25px;
  & .QuoteAvatarWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    & p {
      margin: 0;
      color: var(--Text, #212121);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  & .QuoteAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    & img {
      height: 100%;
    }
  }

  & .QuoCard {
    background: #fff;
    max-width: 377px;
    padding: 22px;
    margin-top: 30px;
    border-radius: 20px;
    filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14));

    & .QuoCardHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #939393;
      margin-bottom: 5px;
      padding-bottom: 10px;
      align-items: flex-start;
    }
    .favButton {
      transform: translateY(-20px);
      border-radius: 0px 0px 20px 20px;
      padding: 9px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      min-height: 61px;
    }
  }
  & .totalsPrice {
    margin: 0;
    color: #4661ed;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.16px;
  }
  .readQuoteBtn {
    border-radius: 0;
  }
}

.proposalModalWrapper {
  margin-top: 92px;
  margin-bottom: 64px;
  overflow-y: auto;
  max-height: 600px;

  @media (max-width: 768px) {
    max-height: 100vh;
    padding-right: 0;
    margin-top: 15px;
    overflow-x: hidden;
  }

  & .proposalImage {
    position: relative;

    & .proposalCover {
      width: 100%;
      min-height: 189px;
      max-height: 400px;
      object-fit: cover;
      & img {
        min-height: 189px;
      }
    }

    & .proposalLogo {
      position: absolute;
      left: 41px;
      bottom: -45px;

      @media (max-width: 768px) {
        left: 23px;
        bottom: -33px;
      }

      & img {
        height: 148px;
        width: 148px;
        border-radius: 50%;

        @media (max-width: 768px) {
          height: 70px;
          width: 70px;
        }
      }
    }

    & .noImage {
      width: 100%;
      height: 189px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cecece75;
      border: 1px solid #d7d7d7;
    }

    & .nologo {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cecece;
      border: 1px solid #d7d7d7;
      height: 148px;
      width: 148px;
      border-radius: 50%;
    }
  }

  & .headingSection {
    margin-top: 14px;
    position: relative;
    padding-left: 27%;
    width: 475px;
    margin-left: 22px;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }

    & .titleName {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 38px;
      letter-spacing: 0.09em;
      color: #1b1a1a;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
      text-decoration: underline;
    }

    & .proposalInformation {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      letter-spacing: 0.09em;
      color: #1b1a1a;

      & svg {
        margin-left: 10px;

        & path {
          fill: #1b1a1a;
        }
      }
    }
  }

  & .topSection {
    position: relative;
    margin-top: 12px;
    & .favBox {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 20px 20px;
      padding: 21px 9px 16px;
      width: 43px;
      height: 61px;
      position: absolute;
      top: -14px;
      right: 64px;
      cursor: pointer;

      & svg {
        & path {
          /* stroke: #939393; */
        }
      }
    }

    & .favorited {
      background: #ffb169 !important;

      & svg {
        & path {
          /* fill: #fff; */
        }
      }
    }
  }

  & .descriptionWrapper {
    margin-top: 35px;

    & p {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.12em;
      color: #212121;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin: 0;
      }
    }
  }

  & .dividerLine {
    height: 1px;
    background-color: #939393;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  & .notesGallery {
    & .notesCard {
      margin-bottom: 16px;
      & .optionImagesWrapper {
        display: flex;
        flex-direction: column;
        gap: 28px;
      }
      & .blueCircle {
        height: 16px;
        width: 16px;
        background: #4661ed;
        border-radius: 100px;
        margin-right: 14px;
      }
      & .optionTitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        letter-spacing: 0.05em;
        color: #000000;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        margin-top: 16px;
        & span {
          font-weight: 600;
          margin-left: 6px;
        }
      }

      & .optionDetail {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.12em;
        color: #595858;
      }

      & .optionImages {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        @media (max-width: 500px) {
          flex-wrap: wrap;
          grid-template-columns: repeat(1, 1fr);
        }

        & span {
          cursor: pointer;
        }

        & .optionCardImages {
          @media (max-width: 768px) {
            width: 100%;
          }

          & img {
            border-radius: 4px;
            max-width: 100%;
            height: 175px;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  & .optionContinueBox {
    border-top: 1px solid #939393;
    border-bottom: 1px solid #939393;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
  }

  & .payWorkBox {
    border-bottom: 1px solid #939393;
    padding-bottom: 12px;
    & .notesHead {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 38px;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & .notesDesc {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12em;
      color: #595858;
      margin: 0;
    }
  }
}

.locationTimeModal {
  & > div {
    & > div {
      padding: 0 !important;
      max-height: 707px;
      overflow: hidden;
    }
  }
}

.downloadPdfWrapper {
  border-radius: 4px;
  background: url('../../assets/download_pdf.png'), rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  height: 175px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 0;
    border-radius: 4px;
  }
  & svg {
    position: relative;
    z-index: 1;
  }
  & span {
    position: relative;
    z-index: 1;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 237.5% */
    letter-spacing: 0.8px;
  }
}

.bottomButton {
  width: 376px;
  margin: auto;
  margin-top: 38px;

  @media (max-width: 768px) {
    width: 100%;
  }

  & button {
    width: 100%;
  }

  & .yellowButton {
    background: #f0eb6f;
    border: 1px solid #212121;
    border-radius: 100px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.12em;
    color: #000000;
    margin-bottom: 32px;

    & svg {
      margin-right: 5px;
    }
  }
}

.selectOptionWrapper {
  border-bottom: 1px solid #939393;
  border-top: 1px solid #939393;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 25%;
  padding: 14px 0;
}

.checkBoxes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 0;
  & li {
    list-style: disc;
    & svg {
      height: 18px;
      width: 18px;
    }
  }
}

.secondSection {
  padding: 0px 41px 30px;
  /* max-height: 410px;
  overflow-y: auto; */
  @media (max-width: 767px) {
    padding: 0 20px 30px;
  }
}

.checkedBox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.titleAndSettings {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
  @media (max-width: 767px) {
    width: calc(100vw - 44px);
    margin: 0 auto;
  }
  & p {
    color: var(--Text, #212121);
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
  }
}

.invoiceCard {
  background: #fff;
  max-width: 377px;
  width: 100%;
  padding: 0px;
  margin-top: 30px;
  border-radius: 20px;
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14));

  & .largeText {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}

.invoiceContent {
  padding: 22px;
}

.lineItem {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  font-family: Lora;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;

  &.lastItem {
    border: 0;
  }

  & > div:last-child {
    text-align: right;
  }
}

.smText {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: right;
}

.price {
  color: #4661ed;
}

.viewPayBtn {
  border-radius: 0;
  background-color: #43b13b;
  color: #fff;
}

.reviewBtn {
  border-radius: 0;
  background-color: #ffb169;
  color: #fff;
}

.chatInvoiceData {
  text-align: right;

  & .nameDate {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
  }

  & .messageDetail {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    & b {
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

.invoiceHeader {
  display: flex;
  gap: 18px;
  justify-content: flex-end;
  &.leftHeader {
    & .nameDate {
      text-align: left !important;
    }
    & .messageDetail {
      text-align: left !important;
    }
  }
}

.finishedWrapperBox {
  text-align: center;
}
.headingFinished {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.2em;
  color: #000000;
}
.boldBoxSize {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0.12em;
  color: #000000;
  margin-bottom: 30px;
}
.finalModalButton {
  & button {
    width: 376px;
    margin: 0 auto 15px auto;
  }
}

.vendorProfileInfoWrapper {
  display: flex;
  gap: 19px;
  align-items: center;
}

.chatProfileImage {
  width: 52px;
  height: 52px;

  border-radius: 100px;
  overflow: hidden;
  & svg {
    padding-top: 0;
    height: 52px;
    width: 30px;
  }
  & div {
    height: auto !important;
  }
}

.plannerDetails {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.invoiceHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buttonsGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  flex: 1;
  & button {
    width: max-content;
    padding: 0 22px;
  }
}

.eventList {
  padding: 0 22px;
  & ul {
    & li {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 38px;
      color: #595858;

      & .boldText {
        font-weight: 500;
        margin-left: 5px;

        & span {
          font-weight: 500;
        }
      }
    }
  }
}

.dateInfo {
  display: flex;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
  }
}

.sidebarHeading {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 20px;
  margin-bottom: 16px;
}

.chatCategories {
  display: flex;
  gap: 14px;
  border-bottom: 1px solid #e0dfdf;
  padding-bottom: 16px;
  flex-wrap: wrap;
  /* cursor: pointer; */
  margin-bottom: 10px;

  & .chatCategory {
    border-radius: 100px;
    border: 1px solid #212121;
    background: #fff;
    padding: 0px 8px;
    color: var(--Text, #212121);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 271.429% */
    white-space: pre;
    max-width: 250px;
    overflow: hidden;
    @media (max-width: 767px) {
      font-size: 10px;
      line-height: normal;
      padding: 8px 24px;
    }
  }
  & .activeCategory {
    border: 1px solid #4661ed;
    color: #4661ed;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 271.429% */
    background: #fff8f2;
    @media (max-width: 767px) {
      font-size: 10px;
      line-height: normal;
      padding: 8px 24px;
    }
  }
}

.VendorsideSuccess {
  background-color: #4661ee;
  padding: 16px;
  & p {
    margin: 0 0 10px;
    color: #fff;
  }
  & button {
    min-height: 38px;
    height: 38px;
    color: #4661ed;
    background-color: #fff;
    max-width: 300px;
    margin: 0 auto;
  }
}

.starIcon {
  & svg {
    margin-bottom: 4px;
  }
  & path {
    fill: #000;
  }
}

.textGrey {
  color: #696969;
}

.VendorSelectedFor {
  background-color: #4661ee;
  padding: 10px;
  & p {
    margin: 0 0 0px;
    color: #fff;
  }
}

.note {
  margin: 8px 0;
  line-height: 24px;
}

.category {
  line-height: 22px;
}

.ChekcBoxItem {
  display: flex;
  border-bottom: 1px solid #575353;
  margin-bottom: 10px;
}

.requirementBox {
  /* display: flex;
  align-items: center; */
  /* border-top: 1px solid #939393;
  padding: 13px 0 18px 0;
  border-bottom: 1px solid #e0e0e0; */
  border-radius: 4px;
  background: #fff;
  padding: 22px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14);
  margin-bottom: 10px;
  &.active {
    background-color: rgb(244, 233, 223);
  }
  @media (max-width: 960px) {
    gap: 20px;
  }

  & .requirementSection {
    margin-right: 35px;

    & .icon {
      margin-right: 10px;
    }

    & span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #212121;
    }

    & .plannedLink {
      color: #4661ed;
      font-weight: 500;
      margin-left: 5px;
      text-decoration: underline;
    }

    & .boldText {
      font-weight: 500;
      margin-left: 5px;
      & span {
        font-weight: 500;
      }
    }
  }
}

.label {
  align-items: center;
}

.CategoryBottomButton {
  display: flex;
  margin-bottom: 23px;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
  & .yellowButton {
    background: #f0eb6f;
    border: 1px solid #212121;
    border-radius: 100px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.12em;
    color: #000000;
    margin-bottom: 0px;

    & svg {
      margin-right: 5px;
    }
  }
}

.sectionWithImage {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contentPart {
  flex: 1;
}

.uploadedImge {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  overflow: hidden;

  & div {
    height: auto;
  }
}
