@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: white;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.multiselectInput{
  background-color: red;
}
.quoteRequestModal{
  text-align: center;
  &>div{
    &>div{
      padding: 35px 76px 39px !important;
      border-radius: 10px;
      @media (max-width: 767px) {
        padding: 74px 20px 24px !important;
      }
    }
  }
  & h3{
    color: #000;
text-align: center;
font-family: Lora;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 181.818% */
letter-spacing: 4.4px;
margin: 0 0 24px;
@media (max-width: 767px) {
  margin: 0 0 25px;
}
  }
  & p{
    color: var(--paragraph, #595858);
text-align: center;
font-family: Lora;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
margin: 0 0 32px;
@media (max-width: 767px) {
  margin: 0 0 25px;
}
  }
  & .createdQuote{
    margin: 0 0 24px;
  }
  & button{
    height: 63px;
    margin-top: 19px;
  }
}
.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.profileProgress {
  width: 100%;
  display: block;
}
.progressText {
  text-align: center;
}
.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 30px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 26px;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.progressBarMain {
  width: 100%;
}

.progressText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  margin-top: 8px;
  margin-bottom: 31px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.listText {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #939393;
  margin: 0;
  cursor: pointer;
}

.requestText {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #212121;

  & svg {
    margin-right: 3px;
  }
}

.closeDetails {
  background: #FFFFFF;
  border: 1px solid #212121;
  border-radius: 100px;
  margin-top: 20px;
}

.listView {
  margin-bottom: 44px;
}


.boxProgress progress {
  width: 100%;
  background-image: linear-gradient(rgba(255, 0, 0, 0.1),
      rgba(255, 0, 0, 0.1) 30%,
      rgba(0, 255, 0, 0.1) 30%,
      rgba(0, 255, 0, 0.1) 70%,
      rgba(255, 0, 0, 0.1) 70%);
  border: 0;
  height: 17px;
  border-radius: 9px;
}

.boxProgress progress[value]::-webkit-progress-bar {
  width: 100%;
  background: transparent;
  background-image:
    -webkit-linear-gradient(#fff,
      #fff);
  border: 0;
  height: 23px;
  background: linear-gradient(0deg, #D9D9D9, #D9D9D9), linear-gradient(0deg, #D9D9D9, #D9D9D9), #D9D9D9;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

.boxProgress progress[value] {
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 30px;
}

.boxProgress progress[value]::-moz-progress-bar {
  /* Firefox */
  border-radius: 30px;
  background-size: 65px, 100%, 100%;
  background-color: transparent;
  background: linear-gradient(0deg, #4661ED, #4661ED), linear-gradient(0deg, #D9D9D9, #D9D9D9), linear-gradient(0deg, #D9D9D9, #D9D9D9), linear-gradient(0deg, #D9D9D9, #D9D9D9), #D9D9D9;
  border-radius: 100px;
}

.boxProgress progress[value]::-webkit-progress-value {
  /* Chrome */
  border-radius: 30px;
  background-size: 65px, 100%, 100%;
  background-color: transparent;
  background: linear-gradient(0deg, #4661ED, #4661ED), linear-gradient(0deg, #D9D9D9, #D9D9D9), linear-gradient(0deg, #D9D9D9, #D9D9D9), linear-gradient(0deg, #D9D9D9, #D9D9D9), #D9D9D9;
  border-radius: 100px;
}

.locationTimeModal {
  &>div {
    &>div {
      padding: 0 !important;
    }
  }
}

.bookingWrapper{
  @media (max-width:767px) {
    margin-top: 50px;
  }
}