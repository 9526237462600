@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: center;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    min-height: 100vh;
    height: 100%;
    @media (max-width: 767px) {
      overflow-y: auto;
      max-height: calc(var(--vh) * 100);
    }
    @media (--viewportMedium) {
      flex-basis: 576px;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      /* border-bottom: 8px solid var(--marketplaceColor); */
    }
  }
}

.fullWidthBox {
  flex-grow: 1;
  display: flex;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */
  background-color: var(--matterColorLight);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }

  & > div {
    flex-basis: 739px !important;
  }
}

.quoteLogo {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  top: -80px;
}

.modalTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}

.tableHeadingName {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.2em;
  color: #000000;
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;

  @media (max-width: 768px) {
    position: relative;
    top: unset;
    left: unset;
    text-align: center;
    margin-top: 60px;
  }
}

.requestTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  margin-top: 14px;
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
  @media (--viewportMedium) {
    /* max-height: 80vh;
    overflow-y: auto; */
  }
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.topbarBox {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
  border: 2px solid #595858;
  padding: 4px;
  border-radius: 100px;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}

.mobileNavbar {
  /* width: 320px !important;
  flex-basis: 320px !important;
  position: fixed !important;
  left: 0;
  top: 0;
  margin-top: 0 !important;
  bottom: 0; */
}
