.root {
}

:global(#location-hero-slider){
    & .root{
      height: 203px;
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 203px;
      background-repeat: no-repeat;
      position: relative;
      text-align: center;
      margin: auto;
      overflow: hidden;
      position: relative;
      padding: 0;
    }
  }

  :global(#event-sizes){
    & .root{
      padding: 0;
      background: #FFFFFF;
      box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 20%);
      border-radius: 8px;
      position: relative;
      display: block;
      text-decoration: none;
      display: flex;
      gap: 18px;
     margin-top: 10px;
     margin-bottom: 10px;
    }
  }