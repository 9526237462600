@import "../../styles/customMediaQueries.css";

.contentMain {
  border: 1px solid #000;

  & .card {
    border-right: 1px solid #000;
    padding: 24px 17px;

    & .packageName {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.12em;
      color: #000000;
      margin-top: 0;
      margin-bottom: 14px;
    }

    & .packagePrice {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 500;
      font-size: 33px;
      line-height: 42px;
      letter-spacing: 0.12em;
      color: #000000;
      margin-bottom: 14px;
    }

    & .acornsCount {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #21201F;
      margin-bottom: 18px;
    }

    & button {
      width: 100%;
      cursor: pointer;
    }

    & .dividerLine {
      background-color: #D4D4D4;
      height: 1px;
      margin-top: 29px;
      margin-bottom: 30px;
    }

    & ul {
      margin: 0;

      & li {
        & .checkIcon {
          margin-right: 13px;
        }
      }
    }

    & .acornsDescription {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #4A4A4A;
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
}