@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.removelabel {
  position: relative;
  bottom: 13px;
}

.fullField {
  display: grid;
  height: 201px;
  grid-template-columns: repeat(1, 1fr);

  &>div {
    width: 100%;
  }
}

.uploadBox {
  text-align: center;

  & .uploadText {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #595858;

    @media(max-width: 768px) {
      display: none;
    }
  }
}

.imagesField {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(3, 1fr);

  @media(--viewportSmall) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media(--viewportMedium) {
    grid-template-columns: repeat(5, 1fr);
  }

  & .uploadText {
    display: none;
  }
}

.thumbnail {

  /* margin: 24px 0 0 0; */
  @media(max-width: 768px) {
    background-color: #fff;
  }

  @media (--viewportLarge) {
    width: 104px;
    height: 104px;

    &:nth-child(even) {
      /* margin: 0 0 24px 0; */
    }
  }

  &>div {
    &>div {
      width: 104px;
      height: 104px;
    }
  }

  & button {
    top: 9px;
    right: 9px;
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    align-items: center;
    justify-content: center;

    & svg {
      top: 8px;
      left: 8px;

      & path {
        stroke: #000;
      }
    }
  }
}

.blankUpload {
  width: 100%;
  position: relative;
  height: 201px;

  &>div {
    width: 100%;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 104px;
    height: 104px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  width: 104px;
  height: 104px;
}

.imagesField {}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: #EDEDED;

  @media (--viewportLarge) {
    /* background-color: var(--matterColorBright); */
  }

  /* border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px; */
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: 56px;
  margin-bottom: 24px;
  position: relative;

  @media (--viewportLarge) {
    display: inline-block;
    width: 100%;
    margin-top: 46px;
  }

  & svg {
    @media(max-width:768px) {
      width: 20px;
      height: 20px;
    }
  }
}

.photoInputSection {
  margin-top: 43px;
}

.colorCodeInput {
  display: flex;
  margin-top: 29px;
  margin-top: 33px;
  flex-wrap: wrap;
  width: 583px;
  justify-content: space-between;
  flex-direction: column;
  gap: 23px;
  margin-bottom: 40px;

  @media(--viewportMedium) {
    align-items: flex-end;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.colorPickerBox {
  & .addColorCode {
    margin-right: 16px;
  }
}

.colorPicker {
  /* width: calc(60% - 9px); */
  /* margin-left: 23px; */

  & input {
    padding: 13px 65px 13px 17px;
  }



  &>div {
    &>span {
      right: 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      transform: translate(-10px, 12px) !important;
    }
  }
}

.categoriesCheckbox {
  margin-top: 29px;
  margin-bottom: 33px;

  & ul {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media(--viewportSmall) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media(--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }

    & li {
      & label {
        margin-bottom: 0;
      }
    }
  }
}

.themeInput {
  margin-bottom: 33px;

}

.descriptionBox {
  &>div {
    background: #FFFFFF;
    border: 1px solid #939393;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 18px;
  }

  & textarea {
    padding: 0;
    border: none;
    height: 104px !important;
    overflow-y: auto !important;
    border: none;
  }
}

.arrowIcon {
  position: absolute;
  right: 43px;
  top: 15px;
}