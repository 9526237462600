@import '../../styles/customMediaQueries.css';

.root {}

.chatCategories {
  display: flex;
  gap: 14px;
  border-bottom: 1px solid #e0dfdf;
  padding-bottom: 23px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  & .chatCategory {
    border-radius: 100px;
    border: 1px solid #212121;
    background: #fff;
    padding: 0px 15px;
    color: var(--Text, #212121);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 271.429% */
    white-space: pre;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 10px;
      line-height: normal;
      padding: 8px 24px;
    }
  }
  & .activeCategory {
    border: 1px solid #4661ed;
    color: #4661ed;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 271.429% */
    /* background: #fff8f2; */
    background: none;
    @media (max-width: 767px) {
      font-size: 10px;
      line-height: normal;
      padding: 8px 24px;
    }
  }
}

.rightSideFullHeading {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 920px) {
    /* display: none; */
  }

  & .rightheadingBox {
    align-items: center;

    display: flex;
    width: 100%;
    padding: 0px 0px;
    justify-content: space-between;
    & .tabs {
      display: flex;
      gap: 18px;
      @media (max-width: 767px) {
        & > div:not(:first-child) {
          display: none;
        }
      }
    }

    & .tab {
      border-radius: 10px 10px 0px 0px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      gap: 19px;
      color: #000;
      font-family: Lora;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.087px;
      background: rgba(255, 255, 255, 0.5);
      position: relative;
      cursor: pointer;
      @media (max-width: 1260px) {
        padding: 16px 8px;
      }
      @media (max-width: 767px) {
        gap: 11px;
        min-width: 224px;
      }
      & .dropdownIcon {
        position: absolute;
        top: 6px;
        right: 17px;
        height: 25px;
        width: 25px;
        cursor: pointer;
        & svg {
          fill: transparent;
        }
        @media (min-width: 767px) {
          display: none;
        }
      }
      & .count {
        color: #fff;
        width: 31px;
        height: 31px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4661ed;
        opacity: 0.75;
        @media (max-width: 767px) {
          width: 22px;
          height: 22px;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      & svg{
        & path{
          stroke:#4661ed;
        }
      }

      &.active {
        background: #4661ed;
        color: #fff;
        & svg{
          & path{
            stroke:#fff;
          }
        }
        & .count {
          opacity: 1;
          background: transparent;
        }
      }
    }

    & .detailUser {
      display: flex;
      align-items: center;
      gap: 26px;

      @media (max-width: 500px) {
        gap: 10px;
      }

      & .backPage {
        display: none;

        @media (max-width: 920px) {
          display: block;
        }
      }

      & .chatProfileImage {
        width: 68px;
        height: 68px;
        overflow: hidden;
        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
        border-radius: 100px;
        border: 3px solid #ffffff;
        & svg {
          padding-top: 0;
          height: 63px;
          width: 30px;
        }
        & div {
          height: auto !important;
        }
        & img {
          width: 68px;
          height: 68px;
         
         
          border-radius: 100px;
          object-fit: cover;

          @media (max-width: 500px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      & .chatDisplayData {
        & .userName {
          font-family: 'Lora';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.15px;
          color: #ffffff;
        }

        & .messageDetail {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #ffffff;
        }
      }
    }

    & .rightTopLink {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;

      & a {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 38px;
        text-decoration-line: underline;
        color: #ffffff;
        margin-right: 28px;
      }

      & .yellowButton {
        background-color: #f0eb6f;
        font-family: 'Lora';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.12em;
        color: #212121;
        min-height: 43px;
        padding: 0 20px;

        & svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.filtersButtons {
  display: flex;
  gap: 8px;
  & .filtersButton {
    border-radius: 4px;
    border: 0.5px solid var(--Disabled-Text, #939393);
    background: #fff;
    padding: 10px;
    color: var(--Text, #212121);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.25px;
    @media (max-width: 1260px) {
      padding: 6px;
    }
    & svg {
      @media (max-width: 767px) {
        height: 26px;
        width: 26px;
      }
    }
  }
  & .filterWithAccordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 62px;
    color: #212121;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.25px;
    position: relative;
    cursor: pointer;

    @media (max-width: 1260px) {
      gap: 16px;
      padding: 6px;
    }
    @media (max-width: 767px) {
      & > svg {
        display: none;
      }
      & .recentText {
        display: none;
      }
    }
    & .filterDropDown {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      background: #fff;
      color: #000;
      z-index: 10;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
      padding: 4px;
      @media (max-width: 1260px) {
        left: unset;
        right: 0;
        min-width: 200px;
      }
      & ul {
        margin: 0;
        & li {
          padding: 3px 10px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: #FFFFFF;
            background-color: #4661ed;
          }
        }
      }
    }
  }
}

.MessageLists {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  flex: 1;
  &.paddingBottomWithInput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.paddingBottom {
    display: flex;
    flex-direction: column;
    & .messageBox{
      flex: 1;
    }

  }
  @media (max-width: 767px) {
    padding: 22px 16px;
    &.paddingBottom {
      padding-bottom: 200px;
    }
  }
  & .chatDateUpdate {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #595858;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: #ededed;
      left: 0;
      height: 1px;
      width: 35%;
      top: 7px;
    }

    &:after {
      content: '';
      position: absolute;
      background: #ededed;
      right: 0;
      height: 1px;
      width: 35%;
      top: 7px;
    }
  }
}

.messageBox {
  padding: 0px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 576px;
  @media (max-width: 767px) {
    height: auto;
  }
  & .messageScroll {
    height: auto;
    overflow: auto;
    margin-bottom: 30px;
    padding-right: 15px;
    flex: 1;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4661ed;
      border-radius: 100px;
    }
  }

  & .transaction {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 0px;
    justify-content: space-between;
    border-bottom: 1px solid #e0dfdf;
    padding: 16px 0;
    position: relative;
    cursor: pointer;
    @media (max-width: 767px) {
      gap: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    & .messagesRightOptionsDesktop {
      text-align: right;
      margin-top: -6px;
      @media (max-width: 767px) {
        text-align: left;
        margin-left: 81px;
      }
      & .menuIconQuote {
        @media (max-width: 767px) {
          position: absolute;
          top: 14px;
          right: -6px;
        }
      }
      & p {
        margin: 0;
      }
      & .status {
        color: #4661ed;
        text-align: right;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.087px;
        @media (max-width: 767px) {
          font-size: 12px;
        }
        &.requested {
          color: #4661ed;
        }
        &.pending {
          color: #eac710;
        }
        &.accepted {
          color: #46d722;
        }
        &.declined {
          color: #ae4040;
        }
        &.expired {
          color: #a7a7a7;
        }
        &.info {
          color: #eac710;
        }
      }
    }
    & .eventTitle {
      color: #000;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
      letter-spacing: -0.087px;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    & .messagesLeftOptions {
      display: flex;
      align-items: center;
      gap: 18px;

      justify-content: space-between;
    }
    & .chatProfileImage {
      width: 63px;
      height: 63px;
      overflow: hidden;
      border: 3px solid #ffffff;
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
      border-radius: 100px;
      & svg {
        padding-top: 0;
        height: 63px;
        width: 30px;
      }
      & div {
        height: auto !important;
      }
      & img {
        width: 63px;
        height: 63px;
 
        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      & .nameDate {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Lora;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.087px;
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 20px;
        }
        & span {
          color: #595858;
        }
      }
      & .timeText {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 300% */
        letter-spacing: -0.087px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          line-height: 14px;
        }
      }
      & .messageDetail {
        color: #313131;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.087px;
        max-width: 100%;
        @media (max-width: 767px) {
          font-size: 10px;
        }
        & .boldText {
          font-weight: 600;
        }
      }
    }
  }

  & .leftMessage {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 20px;

    & .chatProfileImage {
      width: 52px;
      height: 52px;
      overflow: hidden;
      border: 3px solid #FFFFFF;
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
      border-radius: 100px;
      & svg {
        padding-top: 0;
        height: 52px;
        width: 30px;
      }
      & div {
        height: auto !important;
      }
      & img {
        width: 52px;
        height: 52px;

        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      & .nameDate {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 7px;

        & span {
          color: #595858;
        }
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
    }
  }

  & .rightMessage {
    display: flex;
    align-items: flex-end;
    gap: 18px;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    & .chatProfileImage {
      width: 52px;
      height: 52px;
      overflow: hidden;
      border: 3px solid #ffffff;
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.14));
      border-radius: 100px;
      & svg {
        padding-top: 0;
        height: 52px;
        width: 30px;
      }
      & div {
        height: auto !important;
      }
      & img {
        width: 52px;
        height: 52px;
        border-radius: 100px;
      }
    }

    & .chatDisplayData {
      background: #e3e8ff;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
      border-radius: 4px;
      padding: 18px 33px;
      max-width: 70%;

      & .nameDate {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 7px;

        & span {
          color: #595858;
        }
      }

      & .messageDetail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
    }
  }
}

.announceMentBanner {
  background: #4661ed;
  display: flex;
  padding: 22px 35px;
  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    padding: 22px 15px;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  & .iconMike {
    width: 58px;
    height: 58px;
    background-color: #f0eb6f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & button {
    width: max-content;
    color: #212121;
    text-align: center;
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 2px 24px;
    background-color: #fff;
    @media (max-width: 767px) {
      margin: 0 auto;
      min-height: 41px;
    }
  }
  & .bannerDetails {
    flex: 1;
    @media (max-width: 767px) {
      max-width: calc(100% - 78px);
      width: 100%;
      flex: unset;
    }
    & h4 {
      color: #fff;
      font-family: Lora;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    & p {
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 3px 0 3px;
}

.menuItem {
  padding: 0px 9px 0 8px;
  cursor: pointer;

  &:hover {
    color: #FFFFFF;
    background-color: #4661ed;;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menuContent {
  position: absolute;
  z-index: var(--zIndexPopup);
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 0 0 2px 2px;
  transition: var(--transitionStyleButton);
  left: -170px !important;
  width: 200px !important;
}

.expandIcon{
  transform: rotate(180deg);
}