.root {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.25px;

  /* Paragraph */

  color: #595858;

}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  width: 247px;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  height: 51px;
  min-height: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  } */
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);
  width: 247px;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  height: 51px;
  min-height: 0;
  border-radius: 4px;
  border: 0.5px solid #939393;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  color: #595858;

  &:hover,
  &:focus {
    /* border: 1px solid var(--marketplaceColorDark); */
    background: #fff;
  }
}