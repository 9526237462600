@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDatesText {
  flex-shrink: 0;
  /* margin-bottom: 38px; */
  z-index: 1;

  &>div {
    &:nth-child(2) {

      & select {
        border-bottom: 1px solid #939393
      }

      &>div {
        &:nth-child(2) {
          &>div {
            &:after {
              left: 8px !important;
              top: 50px !important;
            }
          }
        }

        &:nth-child(3) {
          display: none;
        }

        &:nth-child(4) {
          &>div {
            &:after {
              left: 8px !important;
              top: 50px !important;
            }
          }
        }
      }
    }
  }
}

.multiselectInput {
  &>div {
    &>div {
      &>div {
        z-index: 9;
      }
    }
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.exactLocation {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #4661ED;
  margin-top: 15px;
  margin-bottom: 32px;
}

.inputBox {
  margin-top: 32px;

  &>div {
    &>div {
      &>div {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}

.locationInputBox {
  margin-top: 26px;

  & .locationAddress {
    flex-wrap: wrap;

    &>div {
      border: none;

      & svg {
        display: none;
      }
    }
  }
}

.blueText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #4661ED;
  margin-top: 15px;
  margin-bottom: 32px;
}

.workcheckBox {
  margin-bottom: 20px;

  & label {
    justify-content: center;
  }
}

.quotesDetailsRoot {
  margin-bottom: 32px;

  &>div {
    &:nth-child(2) {
      background: #FFFFFF;
      border: 1px solid #939393;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 20px;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    & textarea {
      height: 104px !important;
      overflow-y: auto !important;
      border: none;
      padding: 0;
    }
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
    padding: 0 40px;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}