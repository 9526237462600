@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  color: var(--matterColor);
  /* padding-bottom: 10px;
  padding-top: 10px; */

  /* push tabs against bottom of tab bar */
  /* margin-top: auto; */

  /* border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent; */

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>"); */
  background-position: calc(var(--TabNav_linkWidth) + 4px) center;
  /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    /* color: var(--matterColorDark); */
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 18px;
    line-height: 24px;

    width: var(--TabNav_linkWidth);
    /* border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px; */

    /* padding-top: 6px; */
    /* Drop the text to adjust it to correct baseline */
    /* padding-bottom: 2px; */

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center; */
    }
  }
}

.activeTab {
  padding-left: 15px;
}

.selectedLink {
  /* border-bottom-color: var(--matterColorDark); */
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 222% */

  letter-spacing: 0.12em;
  & span {
    font-weight: 700;
    font-size: 18px;

    @media (--viewportLarge) {
      line-height: 24px;
    }
  }

  @media (--viewportLarge) {
    /* width: var(--TabNav_linkWidth); */
    /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>"); */
    /* background-position: right center; */

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>"); */
      /* background-position: right center; */
    }
  }
}

.disabled {
  pointer-events: none;
  text-decoration: none;
  color: #e6e6e6;
  opacity: 0.5;
}

.logoBox {
  margin-bottom: 0;
  @media (--viewportLarge) {
    margin-bottom: 35px;
    width: var(--TabNav_linkWidth);
  }
  & img {
    display: none;

    @media (--viewportLarge) {
      display: block;
      margin: auto;
    }
  }
}

.selectedTabName {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0.12em;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (--viewportLarge) {
    text-align: center;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
  }
}

.tabsWrapper {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.tabsNameBox {
  padding-left: 15px;
}

.tabsDescription {
  display: none;

  @media (--viewportLarge) {
    display: block;
    font-size: 16px;
    margin-top: 53px;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    padding-top: 42px;
    padding-bottom: 45px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }
}
