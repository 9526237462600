@import '../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  height: auto;
  @media (min-width: 768px) {
    min-height: auto;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
  & button{
    & svg{
      & path{
        fill: transparent !important;
        stroke: #6b6b6b;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h3 from global;
  margin-bottom: 24px;
  margin-top: 0px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-top: 3px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.navLink {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 12px !important;
  & svg {
    height: 22px;
    width: 22px;
    color: #6b6b6b !important;
    & path{
fill: #6b6b6b;

    }
    & circle{
      stroke: #6b6b6b;
    }
    & line{
      stroke: #6b6b6b;
    }
  
  }
  & span{
    color: var(--matterColor) !important;
    font-size: 16px !important;
  }

}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  position: relative;

  /* margin-top: auto; */
  /* margin-bottom: 13px; */
  margin: 0;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin: 0;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global;
}

.authenticationGreeting {
  /* Font */
  composes: h4 from global;

  margin-bottom: 24px;
  margin-top: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-top: 3px;
}

.planner{
  & svg{
    & path{
      fill: #000;
    }
  }
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}
