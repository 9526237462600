@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
  /* flex-wrap: wrap; */
  gap: 26px;

  @media (--viewportMedium) {
    margin-top: 31px;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }

  & input {
    padding: 12px 21px;
  }

  & select {
    padding: 10px 13px;
  }

  & label {
    height: 20px;
  }

  & .phoneInput {
    width: calc(60% - 9px);

    @media (max-width: 600px) {
      width: 100%;
    }

    & input {
      padding: 12px 21px 12px 35px;
    }
  }

  & .countryInput {
    width: calc(40% - 9px);

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.inputBox {
  & input {
    padding: 12px 21px;
  }

  & .locationAddress {
    & div {
      &:nth-of-type(1) {
        opacity: 0;
        width: 0;
      }
    }
  }
}

.workRadioButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;

  @media (max-width: 460px) {
    display: block;
  }

  & label {
    margin-bottom: 26px;

    @media (max-width: 500px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
    }

    & span {
      &:nth-of-type(2) {
        @media (max-width: 500px) {
          margin-left: 0;
        }
      }
    }
  }
}

.avatarContainer {
  width: 100%;
  height: 100%;
  & div {
    height: 100%;
  }
  & img {
    object-fit: contain;
    max-height: 273px;
  }
  & .avatar {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-image: unset;
    & img {
      border-radius: 4px;
    }
  }
}

.firstNameRoot {
  width: calc(50% - 9px);

  @media (max-width: 500px) {
    width: 100%;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);

  @media (max-width: 500px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 33px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  /* margin: 24px 0 0 0; */
  border: 1px solid #eee;

  @media (--viewportLarge) {
    /* width: calc(50% - 12px); */
    /* margin: 0 24px 24px 0; */

    &:nth-child(even) {
      /* margin: 0 0 24px 0; */
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  /* margin: 24px 0 0 0; */
  overflow: hidden;
  width: 156px;
  height: 156px;

  @media (--viewportMedium) {
    width: 100%;
    height: 274px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 156px;
  }

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 0;
  background-color: #ededed;
  /* Colors */

  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 4px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }

  /* & div {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  } */
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImagedesktop {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;

  color: #000000;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    /* margin-top: 0;
    margin-bottom: 0; */
  }

  @media (max-width: 768px) {
    text-align: center;
    display: none;
  }
}

.chooseImagemobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
    composes: h4 from global;
    color: var(--matterColor);
    font-weight: var(--fontWeightSemiBold);

    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.12em;

    color: #000000;

    @media (--viewportLarge) {
      font-weight: var(--fontWeightSemiBold);
      /* margin-top: 0;
    margin-bottom: 0; */
    }
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  color: #595858;
  font-size: 10px;
  line-height: 12px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageUpload {
  width: 100%;
  position: relative;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media (--viewportMedium) {
    gap: 18px;
  }
}
